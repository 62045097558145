import DataTable from './DataTable'
import TableCell from '@mui/material/TableCell';
import DownloadPdfLink from './DownloadPdfLink'
import {StyledTableRow} from './tableStyles/twoColourRows'
import { useState, useEffect } from 'react';
import UnableToRetrieveData from './UnableToRetrieveData';
import Tracker from './Tracker';

export default function Accounts({fund, fundsClient}){
    const [dataError, setDataError] = useState(false)
    const [interim, setInterim] = useState()
    const [audited, setAudited] = useState()
    const isFeederFund= [2].includes(fund.id)
    const interimNotRequired = [2].includes(fund.id)
    useEffect(async()=>{
      try
      {
        setInterim(await fundsClient.getInterimAccounts(fund.id))
        setAudited(await fundsClient.getAuditedAccounts(fund.id))
      }
      catch{
        setDataError(true)
      }
        
    },[fund])

    if(dataError) return <UnableToRetrieveData/>
    return(
    <div>
      <Tracker page={'Accounts'}/>
      <h2>Accounts</h2>
      <hr/>
      <DataTable columnNames={ isFeederFund ?['Version', 'Master', 'Feeder' ] : ['Version', 'Year' ]}
         containerStyle={{ marginTop:'2vh', opacity:'0.9'}}>
           {audited?.map(year =>{
             return (<StyledTableRow>
             <TableCell align='center'>Audited</TableCell>
             <TableCell align='center'>
             <DownloadPdfLink arrayBufferPromise={async() => await fundsClient.getAuditedAccount(fund.id,year)} filename='accounts.pdf'>
                  {year}
                  </DownloadPdfLink>
                  </TableCell>
                  {isFeederFund? (
                  <TableCell align='center'>
                  <DownloadPdfLink arrayBufferPromise={async() => await fundsClient.getAuditedFeederAccount(fund.id,year)} filename='accounts-feeder.pdf'>
                    {year}
                    </DownloadPdfLink>
                    </TableCell>
                  ) : null}
              
              </StyledTableRow>
             )
           })}
    </DataTable>
    <br/>
           {interimNotRequired ? null : (
    <DataTable columnNames={['Version', 'Year' ]}
         containerStyle={{ marginTop:'5vh', opacity:'0.9'}}>
           {interim?.map(year =>{
             return (<StyledTableRow>
             <TableCell align='center'>Interim</TableCell>
             <TableCell align='center'>
             <DownloadPdfLink arrayBufferPromise={async() => await fundsClient.getInterimAccount(fund.id,year)} filename='accounts-interim'>
                  {year}
                  </DownloadPdfLink>
                  </TableCell>
              </StyledTableRow>
             )
           })}
    </DataTable>)}
    </div>
    )
}