import { Box, Button, Grid } from "@mui/material"
import ZoomOnView from "../../Animations/ZoomOnView"
import smallCap from '../../images/flying_otus.jpg'
import Tracker from '../Tracker'
import { COLOURS, otusButtonStyleGreen, otusButtonStyleWhite, scrollContainer, scrollSection } from "../../variables"
const strategies = [
    {name:'Otus Smaller Companies Strategy', image:smallCap
    , description: 'Launched in 2004, our long/short strategy is focused on small and mid-sized companies, predominantly in continental Europe. Fundamental bottom-up stock picking is combined with robust portfolio construction designed to protect capital, which results in an actively managed portfolio with variable net market exposure.'},
    {name:'Maga Micro Cap Strategy'
    , image:'https://images.unsplash.com/photo-1457530378978-8bac673b8062?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8'
    , description: 'The Micro Cap strategy launched in 2013 and is a long-only, value-orientated stock picking strategy based on deep research and a collaborative activist approach. This strategy seeks to exploit the inefficiencies in the sub-EUR 250m market cap European universe.'},
]
const Strategy = ({strategy, index}) =>{
    const isEven = index%2 == 0;
    const TextBox = () => {
        return (
            <Box textAlign={isEven ?'left' : 'right'} padding='0vw 10vw 5vh 10vw'>
            <h1>{strategy.name}</h1>
            <br/>
            <br/>
            {strategy.description.split('\n').map(str => <p>{str}</p>)}
            <Button href={"mailto:clientservices@otuscapital.com?subject="+strategy.name+" Information Request"}
             variant='outlined'
              sx={ {... (isEven? otusButtonStyleWhite : otusButtonStyleGreen), textDecoration:'none'}}>
                Request more details
                </Button>
            </Box>
        )
    }
    const ImageBox = () => {
        return (
            <Grid container justifyContent='center' alignItems='center'>
            <img src={strategy.image} width={'60%'} style={{padding:'15px 0 15px 0'}}/>
            </Grid>
        )
    }
    return (
        <Grid container rowSpacing={1} paddingTop={2} paddingBottom={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }} 
        alignItems='center' backgroundColor={isEven ? COLOURS.fontColor : 'white'}
        color={!isEven ? COLOURS.fontColor : 'white'}>
        <Tracker page='Strategies'/>
        <Grid item xs={12} sm={6} md={6}>
            <ZoomOnView side="left" bottomOffset={'10%'}>{isEven ? <TextBox/> : <ImageBox/>}</ZoomOnView>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
        <ZoomOnView side="right" bottomOffset={'10%'}>{!isEven ? <TextBox/> : <ImageBox/>}</ZoomOnView>
        </Grid>
        </Grid>
    )
}
export default function Strategies({navHeight}){
    return(
        <div style={{ paddingTop:navHeight}}>
            {
                strategies.map((strategy, index)=>(<Strategy strategy={strategy} index={index}/>))
            }

        </div>
    )
}