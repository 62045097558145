export default function Legal({style}) {
  return(

    <div style={style}>
    <div className="text-document container">
    <h1 style={{textAlign:'justify'}}>Otus Capital Management</h1>
    <hr/>
    <h2 style={{textAlign:'justify'}}>Legal Notices & Disclosures</h2>

<p><span style={{textDecoration:'underline'}}>IMPORTANT INFORMATION</span>:</p>


<p style={{textAlign:'justify'}}>The content of this website has been prepared by Otus Capital Management Limited (“OCM”) on the basis of information sources and analysis believed to be reliable as at either the time such content was posted in the website or otherwise at the date disclosed. OCM is under no obligation to update such information.</p>
<p style={{textAlign:'justify'}}>Under no circumstances should any part of this website be construed as an offering or solicitation of any offer, and any investment in the products referred to on this website should only be made based on the relevant prospectus.</p>
<p style={{textAlign:'justify'}}>Please note that past performance is not necessarily a guide to future performance. The value of shares can go down as well as up and is not guaranteed. Changes in rates of exchange may also cause the value of shares to fluctuate. Any reference to individual investments should not be taken as a recommendation to buy or sell. Any performance reports should be read in conjunction with the full text and definitions of the relevant fund prospectus.</p>
<p style={{textAlign:'justify'}}>To the fullest extent permitted by law, OCM accepts no liability for any loss or damage which may arise from the access to, use of, or reliance on the content of or information contained on, this website.</p>
<br/>
<p style={{textAlign:'justify'}}><strong>Company Details</strong></p>
<p style={{textAlign:'justify'}}>Otus Capital Management</p>
<p style={{textAlign:'justify'}}>London SW1H 9BU</p>
<p style={{textAlign:'justify'}}>UNITED KINGDOM</p>


{/* <p style={{textAlign:'justify'}}><strong>This is a marketing communication. This is not a contractually binding document. Please refer to the prospectus of the fund and do not base any final investment decision on this communication alone.</strong></p>
<p style={{textAlign:'justify'}}>The content of this document has been approved and communicated by Otus Capital Management Limited (“OCM”) for information purposes only. It does not constitute an offer or solicitation to any person in any jurisdiction to purchase or sell any investment. An offering can be made only by means of the Otus Smaller Companies Fund’s offering memorandum, which includes the terms of the investment including risk factors. No information in this document should be construed as providing financial, investment, tax or other professional advice. The information contained herein is for the sole use of its intended recipient(s) and may not be copied, redistributed or published in whole or in part without the written consent of OCM. Information or opinions in this document may change without notice and OCM is under no obligation to bring any such changes to the attention of any recipient of this document.</p>
<p style={{textAlign:'justify'}}>The Otus Smaller Companies Fund (“the Fund”) has been registered as a regulated mutual fund with the Cayman Islands Monetary Authority under Section 4(3) of the Mutual Funds law (2003 Revision) of the Cayman Islands. However, the investment activities of the Fund will not be regulated or otherwise overseen by the Cayman Islands authorities. The Fund has appointed Otus Capital Management Limited as its investment manager.</p>
<p style={{textAlign:'justify'}}>The distribution of the prospectus relating to the Fund is restricted in certain jurisdictions and accordingly it is the responsibility of any person(s) wishing to make an application to invest therein to inform themselves of, and to observe, all applicable laws and regulations of any relevant jurisdiction. The information below is for general guidance only. The taxation implications of investing in the Fund will depend on the investor’s financial circumstances and country of residence. Applications to invest in the Fund must only be made on the basis of the Fund’s prospectus and subscription documentation.</p>
<p style={{textAlign:'justify'}}><strong>United Kingdom</strong>: The Fund is not a recognised collective investment scheme for the purposes of the Financial Services and Markets Act 2000 (“FSMA”). The promotion of the Fund and the distribution of the prospectus are restricted by law. This document and the Fund’s prospectus may only be issued to persons who are authorised under the FSMA or to persons who are of a kind to whom the Fund may be promoted by an authorised person by virtue of Section 238(5) of the FSMA and Section 4.12 of the Financial Conduct Authority’s Conduct of Business Sourcebook. Many of the protections provided by the United Kingdom’s regulatory regime will not apply to investors in the Fund, including access to the Financial Ombudsman Service and the Financial Services Compensation Scheme.</p>
<p style={{textAlign:'justify'}}><strong>Information for investors in the European Union ("EU") and European Economic Area ("EEA")</strong><br/>In relation to each member state of the EU and EEA (each a "Member State") which has implemented the Alternative Investment Fund Managers Directive ("AIFMD") (and for which transitional arrangements are not available or are no longer available), this document may only be distributed to the extent that it may be lawfully distributed and the interests in the Funds may only be offered or placed in a Member State to the extent that they may lawfully be offered or placed in that Member State in accordance with AIFMD (including at the initiative of the potential investor). In relation to each Member State of the EEA which, at the date of this document, has not implemented AIFMD, this document may only be distributed and the interests in the Fund may only be offered or placed to the extent that this document may be lawfully distributed and the interests in the Fund may lawfully be offered or placed in that Member State (including at the initiative of the potential investor).</p>
<p style={{textAlign:'justify'}}><strong>United States</strong>: The Shares of the Fund (the “Shares”) are neither registered under the Securities Act 1933 (as amended) (the “1933 Act”) nor are they registered under the Investment Company Act 1940 (as amended). The Shares may not be offered, sold or delivered directly or indirectly in the United States or to or for the account or benefit of any "US Person" as defined in Regulation S under the 1933 Act except pursuant to an exemption from, or in a transaction not subject to, the registration requirements of the 1933 Act and any applicable State laws.</p>
<p style={{textAlign:'justify'}}><strong>Cayman Islands</strong>: No invitation may be made to the public in the Cayman Islands to invest in the Fund.</p>
<p style={{textAlign:'justify'}}><span style= {{textDecoration:'underline'}}>Investment Risks</span>: Investment in the Fund carries substantial risk. There can be no assurance that the investment objectives of the Fund will be achieved and investment results may vary substantially over time. Investment in the Fund is not intended to be a complete investment programme for any investor. The value of all investments and the income derived there from can decrease as well as increase. This may be due, in part, to exchange rate fluctuations. The Fund’s base currency is the Euro. Stocks in non-Euro denominated currencies may be bought and sold giving rise to non-Euro currency exposures. Typically, the FX risks of any non-Euro stock positions are hedged by holding an off-setting debit or credit cash balance. The Fund also has non-Euro share classes. Typically, the FX risks of any non-Euro share class exposures are hedged using FX swaps and FX forwards. Hedging mitigates but does not eliminate FX risk. Past performance is no guide to or guarantee of future performance. Persons considering investing in the Fund should read the risk disclosure in the Prospectus.</p>
<p style={{textAlign:'justify'}}><span style={{textDecoration:'underline'}}>Limitation of Liability and Indemnity</span>: The information herein is obtained from sources believed to be reliable but OCM provides no warranty of any kind, implied, expressed or statutory, in regards to the accuracy, adequacy or completeness of the information or data and expressly disclaims liability for errors or omissions in this information and data. OCM accepts no liability for any loss or damage arising out of the use or misuse of or reliance on the information provided including, without limitation, any loss of profits or any other damage, direct or consequential. You agree to indemnify and hold harmless OCM, its affiliates, directors and employees from and against any and all liabilities, claims, damages, losses or expenses, including legal fees and expenses, arising out of your access to or use of the information in this document, save to the extent that such losses or expenses may not be excluded pursuant to relevant law or regulation.</p>
<p style={{textAlign:'justify'}}><span style={{textDecoration:'underline'}}>Copyright and Other Rights</span>: The copyright, trademarks and all similar rights of this document and the contents, including all information, graphics, code, text and design, are owned by OCM.</p>
<p style={{textAlign:'justify'}}>Otus Capital Management is authorised and regulated by the Financial Conduct Authority “FCA”. The firm’s registered address is 29 Queen Anne’s Gate, London SW1H 9BU.</p> */}
</div>
</div>
  );
}