import React from 'react';
import pillar3Disclosures from '../../regulatoryDisclosureDocs/pillar3Disclosures.pdf'
import remuneration from '../../regulatoryDisclosureDocs/remunerationPolicy.pdf'
import esg from '../../regulatoryDisclosureDocs/esg.pdf'
import stewardshipCode from '../../regulatoryDisclosureDocs/stewardshipCode.pdf'
import shareholderEngagementPolicy from '../../regulatoryDisclosureDocs/shareholderEngagementPolicy.pdf'
import proxyVoting from '../../regulatoryDisclosureDocs/proxyVoting.pdf'
import bestExecution from '../../regulatoryDisclosureDocs/bestExecution.docx'
import sharesAndDepositaryReceipts from '../../regulatoryDisclosureDocs/sharesAndDepositaryReceipts.csv'
import debtInstruments from '../../regulatoryDisclosureDocs/debtInstruments.csv'
import equityDerivatives from '../../regulatoryDisclosureDocs/equityDerivatives.csv'
import cfd from '../../regulatoryDisclosureDocs/cfd.csv'
import currencyDerivatives from '../../regulatoryDisclosureDocs/currencyDerivatives.csv'
import Tracker from '../Tracker';

export default function RegulatoryDisclosures({style}) {
  return(

    <div style={style}>
    <div className="text-document container">
    <Tracker page='Regulatory Disclosures'/>
<h1>Otus Capital Management</h1>
<h2>Regulatory Disclosures</h2>
<p><a href={remuneration} target="_blank">Remuneration Policy Statement</a></p>
<p><a href={esg} target="_blank">ESG &amp; Sustainability</a>&nbsp;</p>
<p><a href={stewardshipCode} target="_blank">Stewardship Code</a></p>
<p><a href={shareholderEngagementPolicy} target="_blank">Shareholder Engagement Policy</a></p>
<p><a href={proxyVoting} target="_blank">Proxy Voting Activities 2023</a></p>
{/* <h2>Annual Best Execution Disclosure 2020</h2>
<p><a href={bestExecution} target="_blank">Report 2020</a></p>
<p><a href={sharesAndDepositaryReceipts} target="_blank">Equities - Shares and Depositary Receipts</a></p>
<p><a href={debtInstruments} target="_blank">Debt Instruments</a></p>
<p><a href={equityDerivatives} target="_blank">Equity Derivatives</a></p>
<p><a href={cfd} target="_blank">Contract for Difference</a></p>
<p><a href={currencyDerivatives} target="_blank">Currency Derivatives</a></p> */}
<p>&nbsp;</p>

</div>
</div>
  );
}