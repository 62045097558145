import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea, Box, Grid } from "@mui/material";
import TableRow from '@mui/material/TableRow';
import {COLOURS} from '../variables'
import { Link } from 'react-router-dom'
import DataTable from "./DataTable";
import {PnlTableCell} from './tableStyles/PnlTableCell'

export default function FundCard({fund, setFund}) {
  return (
    <Card sx={{ backgroundColor: COLOURS.mainColor, color: 'white' }}>
      <Link to='fundInfo' onClick={(e) => setFund(fund)} style={{textDecoration:'none', color:'inherit'}}>
      <CardActionArea>
        <CardContent>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} alignItems='center'>
      <Grid item xs={12} sm={6} md={6}>
        <Box>
          <Typography gutterBottom variant="h5" component="div">
            {fund.name}
          </Typography>
          <Typography variant="body2">
            {'All figures calculated as of '+ new Date(fund.lastUpdated).toDateString()}
          </Typography>
          </Box>
        </Grid>
      <Grid item xs={12} sm={6} md={6}>
      <DataTable columnNames={['Daily', 'MTD', 'YTD']}>
        <TableRow
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <PnlTableCell align="center">{fund.performanceDaily*100}</PnlTableCell>
          <PnlTableCell align="center">{fund.performanceMtd*100}</PnlTableCell>
          <PnlTableCell align="center">{fund.performanceYtd*100}</PnlTableCell>
        </TableRow>
      </DataTable>
      </Grid>
            </Grid>
            </CardContent>
      </CardActionArea>
      </Link>
    </Card>
  );
}
