import priips from '../Prospectus/7/priipsKid.pdf'
import priipsGerman from '../Prospectus/7/priipsKidGerman.pdf'
import priipsFrench from '../Prospectus/7/priipsKidFrench.pdf'
import priipsItalian from '../Prospectus/7/priipsKidItalian.pdf'
import priipsSpanish from '../Prospectus/7/priipsKidSpanish.pdf'
import prospectus from '../Prospectus/7/prospectus.pdf'
import prospectusGerman from '../Prospectus/7/prospectusGerman.pdf'

export default function Prospectus({fund}){
    const style = {paddingLeft:'8px'}
    return (
        <div style={{padding:'8px 8px', backgroundColor:'white'}}>
        <h2>PRIIPS KID & Prospectus</h2>
        <hr/>
        {
            fund.id != 7 ? null:
            <div>
            <h4 style={style}><strong>ENGLISH VERSION</strong></h4>
            <p style={style}><a href={priips} target="_blank"><span style={{textDecoration: 'underline'}}>Otus Smaller Companies UCITS Fund PRIIPS KID</span></a></p>
            <p style={style}><span style={{textDecoration: 'underline'}}><a href={prospectus} target="_blank">Otus Smaller Companies UCITS Fund Prospectus</a></span></p>
            <h4 style={style}><strong>GERMAN INVESTORS</strong></h4>
            <p style={style}><span style={{textDecoration: 'underline'}}><a href={priipsGerman} target="_blank">Smaller Companies UCITS Fund PRIIPS KID - German Translation</a></span></p>
            <p style={style}><a href={prospectusGerman}><span style={{textDecoration: 'underline'}}>Smaller Companies UCITS Fund Prospectus &amp; Addendum - Consolidated for Germany</span></a></p>
            <h4 style={style}><strong>FRENCH INVESTORS</strong></h4>
            <p style={style}><span style={{textDecoration: 'underline'}}><a href={priipsFrench} target="_blank">Smaller Companies UCITS Fund PRIIPS KID - French Translation</a></span></p>
            <h4 style={style}><strong>ITALIAN&nbsp;</strong><strong>INVESTORS</strong></h4>
            <p style={style}><span style={{textDecoration: 'underline'}}><a href={priipsItalian} target="_blank">Smaller Companies UCITS Fund PRIIPS KID - Italian Translation</a></span></p>
            <h4 style={style}><strong>SPANISH&nbsp;</strong><strong>INVESTORS</strong></h4>
            <p style={style}><span style={{textDecoration: 'underline'}}><a href={priipsSpanish} target="_blank">Smaller Companies UCITS Fund PRIIPS KID - Spanish Translation</a></span></p>
            <p style={style}>&nbsp;</p>
            </div>
        }
        </div>)
}