import Snapshots from './Snapshots'
import Newsletters from './Newsletters'
import DefaultContainer from './DefaultContainer'
import NeedsFund from './NeedsFund'
import { TableRow } from '@mui/material'

import DataTable from './DataTable'
import TableCell from '@mui/material/TableCell';
import Tracker from './Tracker'

export default function MarketingDocuments({fund, fundsClient}){
return (<NeedsFund fund={fund}><DefaultContainer style={{color:'white'}}>
      <Tracker page={'Snapshots and Newsletters'}/>
    <h2>Snapshots</h2>
      <hr/>
    <Snapshots fund={fund} fundsClient={fundsClient}/>
    <br/>      
    <h2>Newsletters</h2>
    <hr/>
    <Newsletters fund={fund} fundsClient={fundsClient}/>
</DefaultContainer></NeedsFund>)
}