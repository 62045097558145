import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {openAlertDialog} from './Utils'
import { useDialog } from 'react-mui-dialog';
import {Button, Grid} from '@mui/material'
import {otusButtonStyleGreen} from '../variables'
import MfaDialog from './MfaDialog'
import EnableMfaDialog from './EnableMfaDialog'
export default function UserProfile({user, setUser, usersClient}){
    const [values, setValues] = React.useState({
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email
      });
      const {openDialog} = useDialog()
      const [enableMfaOpen, setEnableMfaOpen] = React.useState()
      const [disableMfaOpen, setDisableMfaOpen] = React.useState()
      const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
      };
      const handleSubmit = async () => {
        const emailAvailable = await usersClient.checkEmailAvailable(values.email);
        if(values.email != user.email && !(emailAvailable)){
          openAlertDialog("Warning","Email belongs to another user. Please, use another email", openDialog)
          return;
        }
        if(values.firstName != null || values.lastName != null || values.email != null){
          const updatedUser = {...user,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            update: true

          }
          setUser(updatedUser)
          await usersClient.refreshToken()
        }
        else{
          openAlertDialog("Warning","Values Not Valid", openDialog)
        }
      }
      const updateMfa = async() => 
      {
        await usersClient.updateMfa()
        setUser({ ...user, mfaEnabled: !user.mfaEnabled})
      }
    return (
    <Box padding='0vw 15vw 0 15vw' height='500px'
    component="form"
    backgroundColor='white'
    sx={{
      '& .MuiTextField-root': { m: 1, width: '25ch' },
      minHeight: '0px'
    }}
    noValidate
    autoComplete="off"
  >
    <div style={{paddingTop:'2vh'}}>
      <h2>User Settings</h2>
      <hr/>
      <TextField
        label="First Name"
        value={values.firstName}
        onChange={handleChange('firstName')}
      />
      <TextField
        label="Last Name"
        value={values.lastName}
        onChange={handleChange('lastName')}
      />
      <TextField
      label="Email"
      value={values.email}
      onChange={handleChange('email')}      
    />
    </div>
    <Grid container width='300px' columnSpacing={0} rowSpacing={1}>
    <Grid item xs={12} sm={6} md={6}>
    <Button sx={otusButtonStyleGreen} onClick={(e) => user?.mfaEnabled ? setDisableMfaOpen(true): setEnableMfaOpen(true)}>
      {user?.mfaEnabled ? "Disable MFA" : "Enable MFA"}</Button>
    <EnableMfaDialog open={enableMfaOpen} usersClient={usersClient} setOpen={setEnableMfaOpen} onValidated={(updateMfa)}/>
    <MfaDialog open={disableMfaOpen} usersClient={usersClient} setOpen={setDisableMfaOpen} onValidated={(updateMfa)}/>
    </Grid>
    <Grid item xs={12} sm={6} md={6}>
    <Button sx={otusButtonStyleGreen} onClick={handleSubmit}>Save Settings</Button>
    </Grid>
    </Grid>
  </Box>
);
}