import { useState, useEffect } from "react";
export default function useIsSmallScreen(){
    const [matches, setMatches]  = useState(window.outerWidth >= 821)
    useEffect(() => {
        const listener = e => setMatches(e.matches)
        window.matchMedia("(min-width: 821px)")
        .addEventListener('change', listener);
        return window.matchMedia("(min-width: 821px)").removeEventListener('change', listener)
      }, []);
    return !matches;
}