import BaseClient from "./base";
function base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}
export default class FundsClient extends BaseClient{
    constructor(tokenModel, setToken){
        super(tokenModel,setToken)
        this.basePath = 'funds'
    }
    async get(id) {
      return await this.request({resource: this.basePath + '/'+id, method: 'GET'})
    }
    async getShareClasses(id) {
        return await this.request({resource: this.basePath + "/"+id +'/shareClasses', method: 'GET' })
      }
    async getSnapshot(id, month, year) {
        return base64ToArrayBuffer((await this.request({resource: this.basePath + "/"+id +'/snapshots/'+ year + '-' + month, method: 'GET'})))
      }
    async getNewsletter(id, quarter, year) {
        return base64ToArrayBuffer((await this.request({resource: this.basePath + "/"+id +'/newsletters/'+ year + '-' + quarter, method: 'GET'})))
      }
      async getAuditedAccount(id, year) {
        return base64ToArrayBuffer((await this.request({resource: this.basePath + "/"+id +'/auditedAccounts/'+ year, method: 'GET'})))
      }
      async getAuditedFeederAccount(id, year) {
        return base64ToArrayBuffer((await this.request({resource: this.basePath + "/"+id +'/auditedAccountsFeeder/'+ year, method: 'GET'})))
      }
      async getInterimAccount(id, year) {
        return base64ToArrayBuffer((await this.request({resource: this.basePath + "/"+id +'/interimAccounts/'+ year, method: 'GET'})))
      }
      async getNewsletters(id) {
        return await this.request({resource: this.basePath + "/"+id +'/newsletters', method: 'GET'})
      }
      async getSnapshots(id) {
        return await this.request({resource: this.basePath + "/"+id +'/snapshots', method: 'GET'})
      }
      async getInterimAccounts(id) {
        return await this.request({resource: this.basePath + "/"+id +'/interimAccounts', method: 'GET'})
      }
      async getAuditedAccounts(id) {
        return await this.request({resource: this.basePath + "/"+id +'/auditedAccounts', method: 'GET'})
      }
      async getCountryExposures(id) {
        return await this.request({resource: this.basePath + "/"+id +'/countryExposures', method: 'GET'})
      }
      async getSectorExposures(id) {
        return await this.request({resource: this.basePath + "/"+id +'/sectorExposures', method: 'GET'})
      }
      async getLiquidityExposures(id) {
        return await this.request({resource: this.basePath + "/"+id +'/liquidityExposures', method: 'GET'})
      }
      async getMarketCapExposures(id) {
        return await this.request({resource: this.basePath + "/"+id +'/marketCapExposures', method: 'GET'})
      }
}
