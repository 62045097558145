import { addAlpha } from "./components/Utils";
import JonathanSharpe from '../src/images/jonathanSharpe.jpg'
import AndrewGibbs from '../src/images/andrewGibbs.jpg'
import RobertoCasoni from '../src/images/robertoCasoni.jpg'
import HamishEdsell from '../src/images/hamishEdsell.jpg'
import BenShearn from '../src/images/benShearn.jpg'
import ClaireHolden from '../src/images/ClaireHolden.jpg'
import PaulHale from '../src/images/paulHale.jpg'
import RichardColyer from '../src/images/richardColyer.jpg'
import TamsinQuayle from '../src/images/tamsinQuayle.jpg'
import AlexRoca from '../src/images/alexRoca.jpg'
import EmmaPendlebury from '../src/images/emmaPendlebury.jpg'
import ConorDoran from '../src/images/conorDoran.jpg'
import PeterMorris from '../src/images/peterMorris.jpg'
import DhruvGhurburrun from '../src/images/dhruvGhurburrun.jpg'


export const bios = [
  {
    img: AndrewGibbs,
    title: 'CEO & Portfolio Manager',
    name: 'Andrew Gibbs',
    info: 'Andrew has over 30 years of investment experience in both hedge funds and long-only mutual funds. Andrew founded Otus Capital Management in October 2000 and for the four years to 01 December 2004 he was also Portfolio Manager for the Maga Fund. Formerly a director of M&G Investment Management, Andrew successfully ran the M&G European Smaller Companies Fund between 1998 and 2000, producing a return of 232% since January 1998, placing the fund first out of 116 European funds. This earned the Fund an AA rating from Standard & Poor’s Fund Research and the platinum medal from Lipper ARC. Prior to M&G, Andrew spent four years at a European hedge fund and four years at Lazard Brothers Asset Management. Andrew holds an MA (Hons) in Politics from Edinburgh University and is a member of the UK Society of Investment Professionals (formerly the IIMR).'
  },
  {
    img:RobertoCasoni,
    title: 'Portfolio Manager',
    name: 'Roberto Casoni',
    info: 'Roberto joined OCM in May 2007 and has over 25 years of investment experience. Prior to working at Otus, Roberto was at Citigroup, where from 2003 until 2006, he was a Managing Director and Head of the European Small/Mid Cap research team. Roberto was consistently voted one of the best analysts in Italy in both Extel and Institutional Investor Surveys, including best analyst in Italy in 2004 and 2006 by Institutional Investor2 and best analyst in the Italian Small/Mid Caps in 2004 and 2005 by Extel3. Roberto obtained a Dottore Commercialista (Fiscal advisor and accountant) and a degree in Business and Economics from La Sapienza University in Rome in 1990.',
  },
  {
    img: HamishEdsell,
    title: 'Portfolio Manager',
    name: 'Hamish Edsell',
    info: 'Hamish Edsell joined the OCM team in September 2015. Prior to this, Hamish spent nine years at small and mid cap specialist brokers. Between 2011 and 2015 he helped establish the London office of Hauck & Aufhaeuser Privatbankiers, focusing on equity sales for the German market to UK and US investors. Before this Hamish worked for Helvea for one year and spent 4 years at Berenberg Bank in specialist sales for the European small/mid cap equities team. Hamish graduated from Edinburgh University in 2004 with a MA (Hons) in Arabic and Social Anthropology. He is a CFA Charterholder. '
  },
  {
    title: 'Portfolio Manager',
    name: 'Jonathan Sharpe',
    info: 'Jonathan Sharpe is Portfolio Manager of the Maga Micro Cap Fund. Jonathan joined OCM in March 2013 as an experienced small cap equities manager and launched his own product, the Maga Micro Cap Fund, in November 2013. He came to us from Ratio Asset Management, which Jonathan founded in 2006, where he ran the Ratio European Fund, a European mid cap long/short fund with a peak AUM of $700m. Between 1997 and 2005 Jonathan headed the European Small Cap team at Gartmore and acted as lead investment manager on a range of funds including the European long/short Alphagen Cepheus Fund. Jonathan graduated from Oxford University in 1988 with an MA (Hons) in Engineering Science. He is a member of the CFA.',
    img:JonathanSharpe
  },
  {
    title: 'Portfolio Manager',
    name: 'Tamsin Quayle',
    info: 'Tamsin Quayle joined OCM in December 2017 bringing with her 12 years of investment experience in European small cap equities. Tamsin took a family career break between 2009 and 2017. Prior to this she joined Ratio Asset Management when it was founded by Jonathan Sharpe in 2006 and worked there until 2009. Tamsin’s career started at Gartmore in 1997 where she worked with Jonathan investing in European Small caps until 2005. Tamsin graduated in 1996 from Oxford University with an MA (Hons) in Geography. Tamsin is a CFA Charterholder.',
    img:TamsinQuayle
  },
  {
    img:DhruvGhurburrun,
    title: 'Equity Analyst',
    name: 'Dhruv Ghurburrun',
    info: 'Dhruv is a recent graduate with a Bachelors degree in Economics from University College London and a Masters degree in Financial Analysis from London Business School. He joined Otus in 2022 as an analyst for the Micro-Cap Fund. Prior to OCM, Dhruv had roles as an investment analyst in the asset management industry at Aberdeen Standard Investments and Columbus Point LLP in the UK, as well as AAMIL Asset Management in Mauritius.'
  },
  {
    img:EmmaPendlebury,
    title: 'Head Of Business Development',
    name: 'Emma Pendlebury',
    info: 'Emma joined OCM in July 2021 as Head of Business Development. Prior to this, Emma worked at GSA Capital Partners, where she held the position of Senior Marketing Associate and was involved in all aspects of marketing and investor relations across GSA’s investor base of institutional investors, Fund of Hedge Funds and family offices. Emma started her career in asset management by spending five years as a Marketing and Investor Relations Associate at Cantab Capital Partners. Emma graduated from the University of Cambridge in 2011 with an MA in Law.'
  },
  {
    img:PaulHale,
    title: 'CFO',
    name: 'Paul Hale',
    info: "Paul is the firm's Chief Financial Officer ('CFO') and Chief Compliance Officer ('CCO'). Paul joined OCM at the company's inception in 2000 and oversees all financial and compliance aspects of the company and the funds. He joined OCM from the Royal Bank of Scotland Financial Markets, where he was the Financial Controller of the bank’s derivatives trading business. Prior to this, Paul worked for four years for Bank of Ireland Asset Management. Paul is a Chartered Accountant and a member of the UK Society of Investment Professionals (formerly IIMR). Paul graduated from University College Dublin with a Bachelor of Commerce degree in 1988."
  },
  {
    img:RichardColyer,
    title: 'COO',
    name: 'Richard Colyer',
    info: "Richard Colyer is the firm's Chief Operating Officer ('COO') and Head of Technology at OCM. Richard joined Otus in January 2004 and is primarily responsible for managing the day to day running of Operations and Technology. Working closely with the CFO/CCO and the team he ensures that they remain efficient, streamlined and up to date with any regulatory and industry changes. Richard has previously worked at Greenwich Europe, Fleet National Bank and Harlow Butler Ueda. Richard completed his MSc in Information and Systems Management in 2009."
  },
  {
    img:BenShearn,
    title: 'Trader',
    name: 'Ben Shearn',
    info: 'Ben is our Head of Trading. Ben joined OCM in September 2013, working his way through the operations department to then work as the assistant trader before assuming primary responsibility in November 2016. Ben is responsible for all equity trading including algorithmic, electronic and voice orders. He covers multiple day order management across all venues including dark, lit and crossing networks. Ben manages the research provider review process and all broker allocation decisions. '
  },
  {
    img:AlexRoca,
    title: 'Quantitative Developer',
    name: 'Alex Roca',
    info: 'Alex joined OCM in January 2019 to assist with the development of our bespoke in-house Portfolio Management System, IOP, and other software projects, along with providing cover operationally. Prior to working at Otus, Alex was an Associate at a fintech research firm. Alex holds a BEng in Electrical & Electronic Engineering and Management and an MSc in Financial Mathematics and Computation. '
  },
  {
    img:ConorDoran,
    title: 'Operations Analyst',
    name: 'Conor Doran',
    info: 'Conor joined OCM in September 2021 in a graduate Operations role, to assist the Head of Operations with a wide range of back office tasks. Conor graduated from Queen’s University Belfast with a BSc in Economics with Finance, and has gained financial services experience from placements with Mizuho and Citco Fund Services.'
  },
  {
    img:PeterMorris,
    title: 'Client Services',
    name: 'Peter Morris',
    info: 'Peter’s first exposure to life at Otus came in the form of a summer internship in 2015 whilst studying for his undergraduate degree in History and Economics at Durham University. Since graduating in 2018, he spent a year at a two-man start-up in Munich, followed by three years with the Civil Service Finance Fast Stream, where he took his CIMA and CIPFA exams. Returning to Otus in September 2022 his role covers Client Services alongside assisting the CFO.'
  },
  {
    img:ClaireHolden,
    title: 'Office Manager',
    name: 'Claire Holden',
    info: 'Claire joined OCM in March 2023 as Office Manager and is responsible for the day-to-day running of the office, ensuring that everything runs efficiently and effectively. Alongside this she assists with Client Services tasks and provides general administrative support to the team. Claire brings a wealth of experience to the role with over a decade of experience in office management across a variety of sectors.'
  },
];
export const MainFont = 'Montserrat,sans-serif,Arial'
export const COLOURS = {
  mainColor: '#8a9450',//'#585f34',
  indicatorColor: '#585f34',//'#585f34',
  fontColor: '#8a9450',
  tableHover: addAlpha('#8a9450', 0.8)
};
export  const keys = {googleMaps: 'AIzaSyBoU_iI8jw2-ZZrxP-QJE4xelSXzMKVgHM'}

export const otusButtonStyleWhite = {
  color:'white',
  borderColor:'white',
':hover': {
  backgroundColor: 'rgba(255, 255, 255, .3)',
  borderColor:'white',
  color: 'white',
}
}
export const otusButtonStyleGreen = {
  color: COLOURS.fontColor,
  borderColor:COLOURS.fontColor,
':hover': {
  backgroundColor:addAlpha(COLOURS.mainColor, 0.3),
  borderColor:COLOURS.fontColor,
  color: COLOURS.fontColor,
}
}
export const inputStyle = {
  input: { color: 'white'},
  display:'flex',
  '& label.Mui-focused': {
    color: 'white',
  },
  '& label': {
    color: 'white',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white',
      color:'white'
    },
    '&:hover fieldset': {
      borderColor: 'white',
      color:'white'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
      color:'white'
    },
  }
}
export const inputStyleColour = (colour) => {
  return {
  input: { color: colour},
  display:'flex',
  '& label.Mui-focused': {
    color: colour,
  },
  '& label': {
    color: colour,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: colour
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: colour,
      color:colour
    },
    '&:hover fieldset': {
      borderColor: colour,
      color:colour
    },
    '&.Mui-focused fieldset': {
      borderColor: colour,
      color:colour,
    },
  }
}
}