import moment from 'moment';
import { useEffect, useState } from 'react';

const dtFormat = 'YYYY-MM-DD hh:mm:ss'
export default function useMfa(user) {
  useEffect(()=>{     
    if(user){
      if(user.mfaEnabled){
        const lastMfa = localStorage.getItem('lastMfa')
        const validUntil = moment(lastMfa, dtFormat).add(30,'m').toDate()
        const mfaNotConfirmed = !lastMfa ? true : new Date() >= validUntil;
        setMfaNotConfirmed(mfaNotConfirmed);
      } else{
        setMfaNotConfirmed(false);
      }
    }
  },[user])
  const saveMfaNotConfirmed = notConfirmed => {
    const tempNotConfirmed = user?.mfaEnabled ? notConfirmed : false
    if(tempNotConfirmed) localStorage.removeItem('lastMfa');
    else localStorage.setItem('lastMfa',moment(new Date()).format(dtFormat))
    setMfaNotConfirmed(tempNotConfirmed);
  };
  const [mfaNotConfirmed, setMfaNotConfirmed] = useState(false);
  return {
    setMfaNotConfirmed : saveMfaNotConfirmed,
    mfaNotConfirmed
  }
}