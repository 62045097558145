import { Box, Button, Grid } from '@mui/material'
import { Link } from 'react-router-dom'
import { otusButtonStyleGreen, otusButtonStyleWhite } from '../../variables'
import ContactUs from './ContactUs'
import ZoomOnView from '../../Animations/ZoomOnView'
import StrategiesImg from '../../images/entrance.jpg'
import {COLOURS} from '../../variables'
import useIsSmallScreen from '../App/useIsSmallScreen'
import Tracker from '../Tracker'

const bottomOffset='10%'

const WelcomeSnippet = () => {
const isPhoneScreen = useIsSmallScreen()
  return(
    <Box textAlign={'center'} alignItems='center' minHeight='60vh' padding='10vh 0 0 0'>
    <ZoomOnView side = '' delay={800}>
      <h1 style={{padding:isPhoneScreen ? '0 2vw' : '0 20vw'}}>We are specialist investors in European smaller companies.</h1>
      <br/>
      <br/>
      <h5 style={{padding:isPhoneScreen ? '0 4vw' : '0 30vw'}}>As one of Europe’s longest-running hedge funds, we believe this niche space requires a dedicated approach. Our team’s expertise and access to senior management allows for depth of insight and on-going analysis.</h5>
      </ZoomOnView>
      </Box>
  )
}
const StrategiesSnippet = () => {
  const isPhoneScreen = useIsSmallScreen()
  return(
    <Grid container rowSpacing={{xs:2, sm:1, md:1}} columnSpacing={{ xs: 1, sm: 2, md: 3 }} alignItems='center'
       color={COLOURS.fontColor} padding='10vh 0'>
    <Grid item xs={12} sm={6} md={6}>
    <ZoomOnView side='left' bottomOffset={bottomOffset}>
    <Box textAlign={'left'} padding={isPhoneScreen ? '0 2vw' : '0 10vw'}>
      <h2>Otus manages two strategies that invest in European micro, small and mid-cap companies.</h2>
      <br/>
      <br/>
      <p>We take advantage of the significant alpha opportunities our universe offers, while mitigating against elevated risks it can display during market disruption.</p>
      <Link to='strategies'style={{textDecoration:'none'}}>
        <Button variant='outlined' sx={otusButtonStyleGreen}>Strategies</Button></Link>
      </Box>
      </ZoomOnView>
    </Grid>
    <Grid item xs={12} sm={6} md={6}  alignItems={'center'}>
      <ZoomOnView side='right' bottomOffset={bottomOffset}>
      <img src={StrategiesImg} style={{width:'100%', padding:'0 5vw'}}/>
      </ZoomOnView>
    </Grid>
  </Grid>
  )
}
const TeamsSnippet = () => {
  return(
    <Grid container rowSpacing={1}  columnSpacing={{ xs: 1, sm: 2, md: 3 }} alignItems='center'>
    <Grid item xs={12} sm={0} md={6}></Grid>
    <Grid item xs={12} sm={12} md={6}>
      <ZoomOnView side='right' bottomOffset={bottomOffset}>
    <Box textAlign={'right'} alignItems='center' padding='10vh 5vw'>
      <h2>Our experienced team works in a highly collegiate manner.</h2>
      <br/>
      <br/>
      <p>All our senior investment professionals have dedicated their careers to this universe. They are backed by an operations team that has built first class propietary systems designed specifically to monitor the risks of this asset class.</p>
      <Link to='team'style={{textDecoration:'none'}}>
        <Button variant='outlined' sx={otusButtonStyleWhite}>Team</Button></Link>
      </Box>
      </ZoomOnView>
    </Grid>
  </Grid>
  )
}

export default function PublicPage({navHeight}){
    return(
      <div id='aboutUsbackgroundImage'>
        <Tracker page='About Us'/>
        <div class="container-fluid" style={{paddingTop:navHeight*1.2}}> </div>
        <section>
      <WelcomeSnippet/>
      </section>
      <section style={{backgroundColor:'white'}}>
      <StrategiesSnippet/>
      </section>
      <section>
      <TeamsSnippet/>
      </section>
      <section style={{backgroundColor:'white'}}>
      <ContactUs navHeight={10}/>
      </section>
      </div>
    )
}