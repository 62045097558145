import React, { useEffect, useRef} from 'react'
import { Link } from 'react-router-dom'
import logo from '../images/logo.png'
import UserAvatar from '../components/UserAvatar';
import { ButtonBase } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import MenuIcon from '@mui/icons-material/Menu'
import FundComboBox from './FundComboBox';
import { openAlertDialog } from './Utils';
import { useDialog } from 'react-mui-dialog';
import useIsSmallScreen from './App/useIsSmallScreen';


 const useStyles = makeStyles((theme) =>
  createStyles({
    borderBottom: {
      borderBottom: "2px solid transparent",
      fontWeight:'500',
      "&:hover": {
      textDecoration: "none",
      borderBottom: "2px solid " + 'darkgray',
      }
    },
  }),
);
const NavButton = ({item, onClick}) => {
  const classes = useStyles()
  return <ButtonBase
className={classes.borderBottom} sx={{color:'black'}} onClick={onClick}
size='large'><a data-toggle="collapse" data-target=".navbar-collapse.show">{item.title}</a></ButtonBase>
}
const NavItem =({item, openAlert}) => {
  const dialog = useDialog()
  const test = (e) => {
    openAlertDialog("Need To Choose A Fund","", dialog.openDialog)
  }
  return (
    <li className='nav-item' style={{marginLeft:'2vw'}}>
      {
        (openAlert) ?
        <NavButton item={item} onClick={test}/>
        :
        <Link style={{ color: 'inherit', textDecoration: 'inherit'}} to={''+item.link}><NavButton item={item}/></Link>
      }
     </li>)
}
export default function Navbar({setNavHeight, items, setToken, setMfa, setUser, user, setFund, fund, funds}) {
  const elementRef = useRef(null);
  const updateNavHeight = () => setNavHeight( (prev) =>{
    const navHeight = 90
    if(user) {
      return window.innerWidth < 615 ? navHeight+(window.innerWidth < 382 ? 60 : 40) : navHeight
    }
    return window.innerWidth < 405 ? navHeight+30 : navHeight
  });
  useEffect(()=>{
    window.addEventListener("resize", updateNavHeight);
    return () => window.removeEventListener("resize", updateNavHeight) 
  },[])
  useEffect(()=>{
    updateNavHeight();
  },[elementRef.current?.clientHeight, items])
  
  return (
    <nav class="navbar navbar-expand-lg fixed-top" ref={elementRef} style={{backgroundColor:'white', boxShadow:'0 2px 4px -1px rgba(0,0,0,0.25)'}}>
      <div className='container-fluid'>
  <Link to='/'><a class="navbar-brand" style={{cursor:'pointer'}}><img src={logo} height='80px' alt=""/></a></Link>
  {user?<UserAvatar user={user} setUser={setUser} setToken={setToken} setMfa={setMfa}/> : null}
  {user?<FundComboBox funds={funds} fund={fund} setFund={setFund}/> : null}
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent">
         <MenuIcon sx={{color:'darkgray'}}/>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav ms-auto">
      {items.map((item) => { return <NavItem item={item} openAlert={item.needsFund && !fund}/>})}
      {user?.canCrudUser ? <NavItem item={{title:'USERS', link:'users'}}/> : null}
    </ul>
  </div>
  </div>
</nav>
  )
}