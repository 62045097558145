import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import { COLOURS } from '../../variables';

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)':
   {
        backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  "&:hover": {
    backgroundColor: COLOURS.tableHover
  }
}));