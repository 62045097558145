import { useState, useEffect, useRef } from "react"
import { UserManagamentDataGrid } from "./UsersManagement"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { COLOURS, inputStyleColour, otusButtonStyleGreen } from '../variables';
import {TextField, Grid} from '@mui/material';
import { TryActionWithCatchDialogAsync } from "./Utils";
import { useDialog } from "react-mui-dialog";
import ComboBox from "./ComboBox";
import LoadingIndicator from './LoadingIndicator'
import { trackPromise } from "react-promise-tracker";

const userColumns = [
    {field:'firstName', headerName:'First Name'},
    {field:'lastName', headerName:'Last Name'},
    {field:'company', headerName:'Company'},
    {field:'email', headerName:'Email'},
    {field:'canCrudUser', headerName:'Can CRUD Users', type:'boolean'},
    {field:'role', headerName:'Role', type:'singleSelect', valueOptions: ['User', 'Employee']
    , valueSetter: (params) => {
        let role;
        switch(params.value){
            case 'User': 
            role = 0;
            break;
            case 'Employee': 
            role = 1;
            break;
        }
        return role == undefined ? params.row : {...params.row, role:role}
    }
    , valueGetter:(params) => {
        return params.value == 0 ? 'User' : 'Employee'
    },}
]
const userRoles = [{id:0, name:'User'},{id:1, name:'Employee'}]


export default function UsersManagementUsers({user, setUser, usersClient}){
    const [users, setUsers] = useState([])
    const updateUsers = async () => setUsers(await trackPromise(usersClient.getUsers(),'usersManagementUsers'))
      const ref = useRef(null)
      const {openDialog} = useDialog()
      const deleteUser = async() =>{
        await usersClient.delete(user.id)
        await updateUsers()
    }
    const editUser = async(user) => {
        await TryActionWithCatchDialogAsync(async() => await usersClient.updateUser(user),openDialog);
        return user;
    }
    const [openAddUser, setOpenAddUser] = useState(false)
    useEffect(updateUsers,[openAddUser])
    return (<div ref={ref}>
    <LoadingIndicator trackerArea='usersManagementUsers' innerRef={ref}/>
    <UserManagamentDataGrid editable={true} rows={users} handleRowEdit={editUser} columns={userColumns} selectedItem={user}
            handleDelete={deleteUser} handleAdd={()=>setOpenAddUser(true)} handleRowClick={(x) => setUser(x)}/>
    <AddUserDialog open={openAddUser} setOpen={setOpenAddUser} usersClient={usersClient}/>   

</div>)
}


function AddUserDialog({open,setOpen, usersClient}){
    const {openDialog} = useDialog()
    const textFields = [
        {label:'Email', param:'email', type:'email'},
        {label:'First Name', param:'firstName'},
        {label:'Last Name', param:'lastName'},
        {label:'Company', param:'company'},
    ]
    const [newUser, setNewUser] = useState({canCrudUser:false})
    const[emailAvailable, setEmailAvailable] = useState(true)
    const updateNewUser = (param) => (e) => setNewUser({...newUser, [param]:e.target.value})
    const handleClose = (e) => setOpen(false)
    const addUser = async (e) =>{
        await TryActionWithCatchDialogAsync(async () => {
            if(!emailAvailable) throw 'User With Email Already Exists'
            if(!(newUser?.email && newUser?.firstName && newUser?.role != undefined && newUser?.canCrudUser != undefined)){
                    throw 'There is a missing field'
                }
                await usersClient.create(newUser)
                setNewUser({canCrudUser:false})
                setOpen(false)
            },openDialog)
    }
    const checkEmail = async() => {
        if(newUser.email){
            setEmailAvailable(await usersClient.checkEmailAvailable(newUser.email))
        }
    }
    return (<Dialog open={open} onClose={handleClose} maxWidth='xs'>
        <DialogTitle color={COLOURS.fontColor}>New User</DialogTitle>
        <DialogContent>
        <Grid container paddingTop={'20px'} rowSpacing={{xs:2}} columnSpacing={{ xs: 1, sm: 2, md: 3 }} align = "center">
        {
            textFields.map(textField =>{
                return (<Grid item xs={12}><TextField
                    size="small"
                    sx={inputStyleColour(COLOURS.fontColor)}
                    onChange={updateNewUser(textField.param)}
                    label={textField.label}
                    type={textField.type}
                    error={textField.type == 'email' ? !emailAvailable : false}
                    helperText={textField.type == 'email' ? (!emailAvailable ? 'email not available' : '') : ''}
                    onBlur={textField.type == 'email' ? checkEmail : null}
                    value={newUser ? newUser[textField.param] : null}
                  /></Grid>)
            })
        }
        <Grid item xs={12}>
        <ComboBox label={'Select Role'} displayParam={'name'} objects={userRoles}
        object={userRoles.find(x => x.id == newUser?.role)} handleChange={(e) => {
            setNewUser({...newUser, role: e.target.value?.id, canCrudUser:false})
        }}/>
        </Grid>
        <Grid item xs={12}>
        <FormControlLabel sx={{color:COLOURS.fontColor}}
        control={<Checkbox 
        disabled={newUser?.role==0}
        checked={newUser?.canCrudUser}
        onChange={ (e) => setNewUser({...newUser, canCrudUser:e.target.checked})}
        sx={{color:COLOURS.fontColor}}/>} label="Can CRUD Users" />
        </Grid>
    </Grid>
        </DialogContent>
        <DialogActions> 
            <Button onClick={addUser}  sx={otusButtonStyleGreen}>Add</Button>
            </DialogActions>
      </Dialog>)
}