import { useRef } from 'react'
import {TryActionWithCatchDialogAsync} from './Utils'
import { useDialog } from 'react-mui-dialog'
export default function DownloadPdfLink ({ arrayBufferPromise, filename, children }) {
  const link = useRef()
  const {openDialog} = useDialog()
  const handleAction = async () => {
    if (link.current.href) { return }
    await TryActionWithCatchDialogAsync(async() =>{
      const arrayBuffer = await arrayBufferPromise();
      const blob = new Blob([arrayBuffer], {type: 'application/pdf'});
      const href = window.URL.createObjectURL(blob)
      link.current.download =  filename + '.pdf'
      link.current.href = href
      link.current.click()
    },openDialog, (error) => error == "404" ? "Unable To Find File" : null)
  }

  return (
    <>
      <a role='button' ref={link} onClick={handleAction} className='link-primary'>{children}</a>
    </>
  )
}