import React, { useEffect, useState } from 'react';
import {bios} from '../../variables'
import {Grid, Box} from '@mui/material'
import ZoomOnView from '../../Animations/ZoomOnView';
import Tracker from '../Tracker';
import {COLOURS} from '../../variables'
import logo from '../../images/birdsLogo.png'

export default function Team({navHeight}) {
  const[biographies, setBiographies] = useState(null)
  useEffect(() => {
    setBiographies(bios)
  },[])
  
return (
  <div style={{paddingTop:navHeight, backgroundColor:'white'}}>
        <Tracker page='Team'/>
    <Box textAlign={'center'} justifyContent='center' alignItems='center' flex={1} paddingTop='2vh' paddingBottom='2vh' display='flex'>
      <ZoomOnView side='left'> <h1 style={{color:COLOURS.fontColor}}>Team</h1> </ZoomOnView>
      </Box>
    <Grid container padding = '0 10vw' justifyContent='center' alignItems='center' flex={1}
    paddingTop='2vh' paddingBottom='2vh' display='flex'
     rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 10 }}
     >
    {biographies?.map((bio) => (
          // <Grid item xs={12} sm={6} md={4}>
          // <BiographyImage bio={item}/>
          // </Grid>
          <Grid item xs={12} sm={12} md={12}>
          <BioInfo bio={bio}/>
          </Grid>
      ))}
  </Grid>
    </div>
  );
}


const BioInfo = ({bio}) => {
  const imgSize = '70%'
  return (
    <Grid container justifyContent={'center'}
    rowSpacing={3}
    >
      <Grid item  xs={12} sm={12} md={2} textAlign='center'>
      <img
              src={bio.img ?? logo}
              alt=""
              width={imgSize}
              style={{ borderRadius: '50%', boxShadow: '0 0 0 3px rgba(0, 0, 0, 0.2)'}}
          />
    </Grid>
      <Grid item  xs={12} sm={12} md={10}>
    <p style={{fontSize:20, fontWeight:'bold', }}>{bio.name + ' - '}
    <span style={{fontSize:20, fontWeight:'lighter'}}>{bio.title}</span></p>
    <p>{bio.info}</p>
    </Grid>
    </Grid>
  )
}