import { Button, TextField, Box, Grid } from "@mui/material";
import { useState } from "react";
import { otusButtonStyleGreen, inputStyleColour } from "../../variables";
import { useDialog } from "react-mui-dialog";
import { openAlertDialog } from "../Utils";
import {useNavigate} from 'react-router-dom'
import {COLOURS} from '../../variables'
import Tracker from "../Tracker";

const tempStyle = {... inputStyleColour(COLOURS.fontColor), minWidth:'300px'}
export default function RequestAccess({navHeight, usersClient}){
    const {openDialog} = useDialog()
    const navigate = useNavigate()
    const [values, setValues] = useState({
        firstName:'',
        lastName:'',
        email:'',
        company:'',
        fundsInterestedIn:'',
    })
    const sendLogInRequest = async ()=>{
        try{
            await usersClient.sendLogInRequest(values)
            openAlertDialog('Request Sent Successfully', 'We will be in touch with further details', openDialog, () => navigate('/'))
        }
        catch(error){
            openAlertDialog('Something Went Wrong', error.message, openDialog)
    
        }
    }
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
      };
    return(
        <form style={{paddingTop:navHeight, backgroundColor:'white', color:COLOURS.fontColor}}>
            <Tracker page='Request Access'/>
            <Grid container alignItems='center' justifyContent='center'>
            <Box textAlign={'center'} paddingTop='10vh' paddingBottom='20vh'>
                <h2>Enter Details</h2>

                <br/>
            <TextField type='text' size='small' label='First Name' sx={tempStyle} value={values.firstName} onChange={handleChange('firstName')} />
            <br/>
            <TextField type='text' size='small' label='Last Name' sx={tempStyle} value={values.lastName} onChange={handleChange('lastName')} />
            <br/>
            <TextField type='text' size='small' label='Email' sx={tempStyle} value={values.email} onChange={handleChange('email')} />
            <br/>
            <TextField type='text' size='small' label='Company' sx={tempStyle} value={values.company} onChange={handleChange('company')} />
            <br/>
            <TextField type='text' size='small' multiline rows={3} inputProps={{ style: {color:COLOURS.fontColor} }} label='Fund(s) Interested In' sx={tempStyle}
             value={values.fundsInterestedIn} onChange={handleChange('fundsInterestedIn')} />
            <br/>
            <Button sx={{...otusButtonStyleGreen, float:'right'}} variant='outlined' onClick={(e) => sendLogInRequest()}>SEND REQUEST</Button>
            </Box>
            </Grid>
        </form>
    )
}