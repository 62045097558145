import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {inputStyleColour, COLOURS} from '../variables'

export default function ComboBox({label,displayParam, object, objects, handleChange}){
        return (
        <FormControl sx={{...inputStyleColour(COLOURS.fontColor) }} size="small">
            <InputLabel sx={{color:COLOURS.fontColor}}>{label}</InputLabel>
            <Select
            sx={{color:COLOURS.fontColor}}
            value={object??""}
            label={label}
            onChange={handleChange}
            >
            {objects?.map((obj)=>{
                return <MenuItem sx={{color:COLOURS.fontColor}} value={obj}>{ displayParam ? obj[displayParam] : obj}</MenuItem>
            })}
            
            </Select>
        </FormControl>
        )
}