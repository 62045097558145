import { useState } from 'react';
import {openAlertDialog} from '../Utils'
import { useDialog } from 'react-mui-dialog';
export default function useUser(usersClient) {
  const { openDialog } = useDialog()
  const saveUser = user => {
    if(!user) 
    {
      setUser(null)
      return;
    }
    if(user.update) 
    {
        usersClient.Update(user).then(res=>{
          openAlertDialog('Completed', 'Settings Saved Successfully',openDialog )
        }).catch(error => {
          openAlertDialog('Something Went Wrong', 'Please, try again',openDialog )
        })
    }
    setUser({
      id: user?.id,
      firstName:user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      mfaEnabled: user?.mfaEnabled,
      canCrudUser: user?.canCrudUser
    });
  };
  const [user, setUser] = useState();
  return {
    setUser: saveUser,
    user
  }
}