import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDialog } from 'react-mui-dialog';
import { COLOURS, inputStyleColour, otusButtonStyleGreen } from '../variables';
import { TryActionWithCatchDialogAsync } from './Utils';
export default function MfaDialog({open, setOpen, usersClient, onValidated}){
  const[code, setCode] = useState('')
  const[validated, setValidated] = useState(false)
  const {openDialog} = useDialog()
  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick")  return;
    setOpen(false)
  }
  const handleValidate = async () =>{
    await TryActionWithCatchDialogAsync(async ()=>{
      if(await usersClient.checkMfaCode(code) != 'true') throw '401'
      else{
        setOpen(false)
        setValidated(true)
      }
    },openDialog, (error) => error=='401' ? 'Code not valid' : null)
    }
    useEffect(()=>{
      if(onValidated && validated) 
      {
        onValidated()
      }
    },[validated])
    return(
        <Dialog open={open} onClose={handleClose}>
        <DialogTitle color={COLOURS.fontColor}>Enter Two Factor Code</DialogTitle>
        <DialogContent>
          <TextField
                autoFocus
                margin="dense"
                label="Code"
                type="text"
                onChange={(e) => setCode(e.target.value)}
                value={code}
              sx={inputStyleColour(COLOURS.fontColor)}
              fullWidth
                variant="standard"
              />
        </DialogContent>
        <DialogActions> <Button onClick={(e) => handleValidate()}  sx={otusButtonStyleGreen}>Validate</Button></DialogActions>
      </Dialog>)
}