import { textAlign } from '@mui/system';
import React from 'react';

export default function TermsAndConditions({style}) {
  const defaultStyle = {textAlign:'justify'}
  return(

    <div style={style}>
    <div className="text-document container">
    <h1 style={{textAlign:'justify'}}>Otus Capital Management</h1>
    <hr/>
    <h2 style={{textAlign:'justify'}}>Terms & Conditions</h2>
<p style={defaultStyle}><span style={defaultStyle}>This website (http://www.otuscapital.com) is a website operated by Otus Capital Management LP (&ldquo;OCM&rdquo;). The information on the website is communicated by OCM both inside and outside the United Kingdom.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>OCM is registered in England and Wales with registration number 4095762 and has its registered office at 29 Queen Anne&rsquo;s Gate, London, SW1H 9BU, United Kingdom.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>Telephone +44 (0)20 7227 0000.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>Fax +44 (0)20 7227 0001.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>OCM is authorised and regulated by the Financial Conduct Authority (&ldquo;FCA&rdquo;) and is entered on the Financial Services Register (Register Number 195277).</span></p>
<h2 style={defaultStyle}><span style={defaultStyle}>Investment Information</span></h2>
<p style={defaultStyle}><span style={defaultStyle}>The information provided on this website is not intended for distribution to, or use by, any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject OCM or its affiliates to any registration requirement within such jurisdiction or country.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>The distribution of the information contained in this website in certain countries may be restricted by law and persons who access it are required to inform themselves and to comply with any such restrictions. This information does not constitute an offer or solicitation in any jurisdiction. Prospective investors should inform themselves as to applicable legal requirements, exchange control regulations and taxes in the countries of their citizenship, residence or domicile.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>This website is published solely for informational purposes and has no regard to the specific investment objectives, financial situation or particular needs of any person or entity. Information contained herein is believed to be reliable but no warranty is given as to its accuracy or completeness and views and opinions, whilst given in good faith, are subject to change without notice. The information contained in this website is not intended to constitute, and should not be construed as, investment advice. Potential investors in the Funds that are described, or referred to, in this website should seek their own independent financial advice.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>An application for shares, limited partnership interests or units in any of the Funds referred to on this website should only be made having read fully the relevant prospectus or offering memorandum (together a &ldquo;Prospectus&rdquo;) and subscription documentation. It is your responsibility to read such Prospectus and by making an application you will be deemed to represent that you have read such Prospectus and agree to be bound by its contents.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>You should note that, if you choose to invest in any Fund described or referred to in this website, your capital will be at risk and you may therefore lose some or all of any amount that you choose to invest.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>&nbsp;</span></p>
<p style={defaultStyle}><span style={defaultStyle}>&nbsp;</span></p>
<h2 style={defaultStyle}><strong><span style={defaultStyle}>Investment Risks</span></strong></h2>
<p style={defaultStyle}><span style={defaultStyle}>Investment in the Funds carries substantial risk. There can be no assurance that the investment objectives of the Funds will be achieved and investment results may vary substantially over time. Investment in the Funds is not intended to be a complete investment programme for any investor. Investment in the Funds on this website is intended for experienced investors who are able to understand and accept the risks involved.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>The value of all investments and the income derived therefrom can decrease as well as increase. Past performance is no guide to or guarantee of future performance.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>The levels and bases of and reliefs from taxation may change. Any tax reliefs referred to are those currently available and their value depends on the circumstances of the individual investor. Investors should consult their own tax adviser in order to understand any applicable tax consequences.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>Nothing contained on our website constitutes investment, legal, tax or other advice, nor is it to be relied upon when making investment or other decisions. The information on our website is provided solely on the basis that you make your own investment decisions. You should obtain relevant and specific professional advice before making any decision to enter into an investment transaction.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>Persons considering investing in the Funds should read the risk disclosures in the relevant Prospectus.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>Unless otherwise stated, returns are after trading costs, administration, investment management and performance fees (&ldquo;Net&rdquo;).</span></p>
<p style={defaultStyle}><span style={defaultStyle}>Gross returns are presented before investment management and performance fees, and include the reinvestment of all income, but are after trading costs and administration fees. Actual returns will be reduced by investment management and performance fees and other expenses that may be incurred in the management of the relevant Fund.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>&nbsp;</span></p>
<h2 style={defaultStyle}><strong><span style={defaultStyle}>Your Use of this Website</span></strong></h2>
<p style={defaultStyle}><span style={defaultStyle}>These terms and conditions set out the terms on which you may make use of this website and apply to every page of this website, whether or not these terms and conditions appear on each individual page. Please read these terms and conditions carefully before you start to use this website. By using this website, you have indicated that you accept these terms and conditions and that you agree to abide by them. If you do not agree to these terms and conditions, please refrain from using this website.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>You must NOT use this website (or permit or procure others to use it) as follows:</span></p>
<p style={defaultStyle}><span style={defaultStyle}>(A) for any unlawful, improper or illegal purpose or activity;</span></p>
<p style={defaultStyle}><span style={defaultStyle}>(B) to violate OCM&rsquo;s or any third party&rsquo;s copyright, trademark, proprietary or other intellectual property rights;</span></p>
<p style={defaultStyle}><span style={defaultStyle}>(C) to damage OCM&rsquo;s name or reputation or that of OCM&rsquo;s affiliated companies or any third parties;</span></p>
<p style={defaultStyle}><span style={defaultStyle}>(D) to introduce viruses, worms, harmful code and/or Trojan horses onto the internet or into this website or any other entity&rsquo;s systems and it is your responsibility to ensure that whatever you download or select for your use from this website is free from such items; and/or</span></p>
<p style={defaultStyle}><span style={defaultStyle}>(E) to transmit confidential or proprietary information, except solely at your own risk.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>When using this website, you must comply with all applicable local, national and international laws and regulations, including those related to data privacy, international communications and exportation of technical or personal data. It may be illegal to download the information contained on this website in certain countries and OCM and their affiliated companies disclaim all responsibility if you download any information from this website in breach of any law or regulation of the country in which you reside.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>&nbsp;</span></p>
<h2 style={defaultStyle}><strong><span style={defaultStyle}>Username and Password</span></strong></h2>
<p style={defaultStyle}><span style={defaultStyle}>You have been provided with a username and password to access this website. You must treat this information as confidential and you must not disclose it to any third party or allow any third party to access this website using your username or password. You agree to notify OCM immediately if you suspect unauthorised use of this website or of your username or password.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>&nbsp;</span></p>
<h2 style={defaultStyle}><strong><span style={defaultStyle}>Termination and Suspension of Access to this Website</span></strong></h2>
<p style={defaultStyle}><span style={defaultStyle}>OCM reserves the right to deny you access to this website, or immediately to suspend or terminate your access to this website, or to disable any username or password (whether chosen by you or allocated by OCM) at any time if your use of this website (in OCM&rsquo;s sole discretion) in any manner breaches these terms and conditions.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>&nbsp;</span></p>
<h2 style={defaultStyle}><strong><span style={defaultStyle}>Your Personal Data</span></strong></h2>
<p style={defaultStyle}><span style={defaultStyle}>Any personal data that we collect and process about you in relation to your use of this website will be done in accordance with our Privacy Notice that can be found&nbsp;</span><span style={defaultStyle}>here</span><span style={defaultStyle}>. This includes details of how calls you make to us may be recorded in order for us to comply with our legal and regulatory obligations.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>If you are an individual, the Privacy Notice applies to OCM&rsquo;s processing of your personal data. If you are acting on behalf of a company, the Privacy Notice applies to the OCM&rsquo;s processing of any personal data that you provide to it or that it otherwise processes in connection with the products and services that OCM provides to you.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>Your obligations in respect of your personal data</span></p>
<p style={defaultStyle}><span style={defaultStyle}>You must ensure that any personal data that you provide to the OCM is accurate and up to date, and that you promptly notify OCM if you become aware that it is incorrect.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>In respect of any personal data relating to a third party individual that you provide to the OCM, you must:</span></p>
<p style={defaultStyle}><span style={defaultStyle}>(A) have satisfied a statutory ground under applicable data protection laws permitting you to transfer the relevant personal data to OCM for it to use in accordance with the Privacy Notice;</span></p>
<p style={defaultStyle}><span style={defaultStyle}>(B) have notified the third party individual that you are providing their personal data to OCM and explained the reasons for this;</span></p>
<p style={defaultStyle}><span style={defaultStyle}>(C) draw the attention of the third party individual to the Privacy Notice; and</span></p>
<p style={defaultStyle}><span style={defaultStyle}>(D) promptly notify the third party individual of any changes to the Privacy Notice that OCM notifies to you.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>If you are acting on behalf of a company, you must take reasonable steps to notify your employees, officers (and their associates, including family members), trustees, and beneficial owners that OCM may process their personal data in connection with these terms and conditions and the products and services that OCM provides under them. You must also draw their attention to the Privacy Notice.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>&nbsp;</span></p>
<h2 style={defaultStyle}><strong><span style={defaultStyle}>Intellectual Property Rights</span></strong></h2>
<p style={defaultStyle}><span style={defaultStyle}>The entire contents of this website are subject to copyright with all rights reserved. The copyright, trademarks and all similar rights of this website and the contents, including all information, graphics, code, text and design, are owned by OCM (or its licensors).&nbsp;</span></p>
<p style={defaultStyle}><span style={defaultStyle}>&nbsp;</span></p>
<p style={defaultStyle}><span style={defaultStyle}>You may download or print individual sections of the site for your personal use and information only provided that you ensure that all copyright notices and other proprietary notices are displayed on such downloaded or printed sections. You may not reproduce (in whole or in part), transmit (by electronic means or otherwise), modify, link into or use for any public or commercial purpose the site without the prior written permission of OCM.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>Nothing on this website should be construed as granting any licence or right in relation to any of our trademarks or those of our affiliated companies or any third party.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>&nbsp;</span></p>
<h2 style={defaultStyle}><strong><span style={defaultStyle}>Linked Websites</span></strong></h2>
<p style={defaultStyle}><span style={defaultStyle}>Where a link to a non-OCM website exists, to the extent permitted by the FCA Rules, OCM does not accept responsibility for the content of such site nor the products, services or other items offered through that site.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>&nbsp;</span></p>
<h2 style={defaultStyle}><strong><span style={defaultStyle}>No Warranty</span></strong></h2>
<p style={defaultStyle}><span style={defaultStyle}>This website, including information and materials contained in this website, text, graphics, software, links and other items are provided &ldquo;as is,&rdquo; &ldquo;as available&rdquo;. OCM does not warrant the accuracy, adequacy, completeness, timeliness or availability of the information and data contained in this website, and expressly disclaims liability for errors or omissions in this website. There is no warranty of merchantability, no warranty of fitness for a particular purpose, no warranty of non-infringement, no warranty of any kind, implied, express or statutory, in conjunction with this website.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>&nbsp;</span></p>
<h2 style={defaultStyle}><strong><span style={defaultStyle}>Limitation of Liability</span></strong></h2>
<p style={defaultStyle}><span style={defaultStyle}>To the extent permitted by applicable laws and regulations, in no event will OCM be liable for any damages, including, without limitation, direct or indirect, special, incidental, or consequential damages, losses or expenses arising in connection with this website or use or misuse thereof or the inability by any party to use such website, or in connection with any failure of performance, error, omission, interruption, defect, delay in operation or transmission, computer virus or line or system failure, even if OCM, or representatives thereof, are advised of the possibility of such damages, losses or expenses.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>&nbsp;</span></p>
<h2 style={defaultStyle}><strong><span style={defaultStyle}>Indemnity</span></strong></h2>
<p style={defaultStyle}><span style={defaultStyle}>As a condition of your use of this website, you agree to indemnify and hold harmless OCM and its affiliates, and the partners and employees of OCM and its affiliates from and against any and all liabilities, claims, damages, losses or expenses, including legal fees and expenses, (together, &ldquo;Losses&rdquo;) arising out of your access to or use of this website, save to the extent that OCM may not exclude or limit its own liability for such Losses pursuant to applicable laws or regulations.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>&nbsp;</span></p>
<h2 style={defaultStyle}><strong><span style={defaultStyle}>Law and Variation</span></strong></h2>
<p style={defaultStyle}><span style={defaultStyle}>This website is established in England by OCM in accordance with and shall be governed by, and your browsing in and use of this website shall be deemed acceptance of, the laws of England and Wales. A condition of using this website is that in the event of any dispute or proceeding you irrevocably submit to the exclusive jurisdiction of the English Courts and waive any objection to proceedings in such courts on the grounds of venue or on the grounds that the proceedings have been brought in an inconvenient forum.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>OCM may revise these terms and conditions at any time by amending this page. You are expected to check this page from time to time to take notice of any changes OCM makes, as they are binding on you.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>Each time you use this website, a condition of use is that you comply with the above terms.</span></p>
<p style={defaultStyle}><span style={defaultStyle}>&copy; Copyright 2021, OCM LP. All rights reserved.</span></p>
</div>
</div>
  );
}