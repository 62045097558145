import { Link, useLocation } from 'react-router-dom'

export default function Footer(){
    const prevPath = useLocation()?.pathname;
    const investorsPage= prevPath?.includes('/investors') ? 'investors/' : ''
return(
<footer id="footer">
<div class="main-footer typo-light">
<div class="container">
<div class="row">


<div class="col-xs-12 col-sm-6 col-md-2">
<div class="widget">
<ul class="thumbnail-widget">
<li> <div class="thumb-content"><Link to={investorsPage+'privacy'}>Privacy</Link></div>  </li>
<li> <div class="thumb-content"><Link to={investorsPage+'termsAndConditions'}>Terms & Conditions</Link></div>  </li>
<li> <div class="thumb-content"><Link to={investorsPage+'legal'}>Legal</Link></div>  </li>
<li> <div class="thumb-content"><Link to={investorsPage+'regulatoryDisclosures'}>Regulatory Disclosures</Link></div>  </li>
</ul>
</div>
</div>

<div class="col-xs-12 col-sm-6 col-md-10">
<div class="widget">
<p style={{fontSize: 12}}>The material contained in this Web site is for your private information and we are not soliciting any action based upon it. Opinions expressed are our present opinions only and may be changed at any time after publication. The material is based upon information which we consider reliable, but we do not represent that it is accurate or complete, and should not be relied upon as such. Past performance is not an indication of future performance. The source of all data contained on this website is from Otus Capital Management, unless otherwise stated.</p>
</div>
</div>

</div>
</div>
</div>
  
<div class="footer-copyright">
<div class="container">
<div class="row">
<div class="col-md-12 text-center">
<p>{'Copyright '+new Date().getFullYear()+' Otus Capital Management'}</p>
</div>
</div>
</div>
</div>
</footer>
)
}