import DefaultContainer from "./DefaultContainer";
import NeedsFund from "./NeedsFund";
import Accounts from "./Accounts";

export default function AccountsWrapper({fund, fundsClient}){
    return(<NeedsFund fund={fund}><DefaultContainer style={{color:'white'}}>
        <Accounts fund={fund} fundsClient={fundsClient}/>
        </DefaultContainer>
        </NeedsFund>
    )

}