
import MyDatePicker from './MyDatePicker';
import { Grid } from '@mui/material';

export default function FromToDatePicker({onFromChange, onToChange}){
    return(<div style={{maxWidth:'350px'}}>
    <Grid container rowSpacing={{xs:3}} columnSpacing={{ xs: 1, sm: 2, md: 3 }} alignItems='center'>
    <Grid item xs={12} sm={6} md={6}>
      <MyDatePicker label='from' onChange={onFromChange}/>
      </Grid>
    <Grid item xs={12} sm={6} md={6}>
      <MyDatePicker label='to' onChange={onToChange}/>
      </Grid>
      </Grid>
      </div>
    )
  }