import { useState, useEffect, useRef } from "react"
import { UserManagamentDataGrid } from "./UsersManagement"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { COLOURS, inputStyleColour, otusButtonStyleGreen } from '../variables';
import {TextField, Grid} from '@mui/material';
import { openAlertDialog, TryActionWithCatchDialogAsync } from "./Utils";
import { useDialog } from "react-mui-dialog";
import ComboBox from "./ComboBox";
import LoadingIndicator from './LoadingIndicator'
import { trackPromise } from "react-promise-tracker";
import MultipleSelectChip from "./MultipleSelectChip";

const fundColumns = [
    {field:'name', headerName:'Fund'},
]
export default function UsersManagementFunds({user, usersClient}){
    const [funds, setFunds] = useState([])
    const [fund, setFund] = useState(null)
    const [openAddFund, setOpenAddFund] = useState(false)
    const ref = useRef(null)
    const {openDialog} = useDialog() 
    const updateFunds = async () => {
        if(user){
            setFunds(await trackPromise(usersClient.getFundsForUser(user?.id),'usersManagementFunds'))
            setFund(null)
        }
    }
    useEffect(updateFunds,[user])
    useEffect(updateFunds,[openAddFund])
    const removeFund = async() =>{
        await usersClient.removeFund(user.id, fund.id)
        await updateFunds()
    }
    const handleAddFund = () => {
        if(user?.role == 0){
            setOpenAddFund(true)
        }
        else{
            openAlertDialog("Error", "You cannot add funds for Employees",openDialog)
        }
    }
    return (<div ref={ref}>
    <LoadingIndicator trackerArea='usersManagementFunds' innerRef={ref}/>
    <UserManagamentDataGrid rows={funds} columns={fundColumns} selectedItem={fund}
            handleDelete={removeFund} handleAdd={handleAddFund} handleRowClick={(x) => setFund(x)}/>
    <AddFundsDialog open={openAddFund} currentFunds={funds} setOpen={setOpenAddFund} user={user} usersClient={usersClient}/>   

</div>)
}


function AddFundsDialog({open,setOpen, currentFunds, user, usersClient}){
    const {openDialog} = useDialog()
    const [availableFunds, setAvailableFunds] = useState([])
    const [newFunds, setNewFunds] = useState([])
    useEffect(async() => {
        if(user){
            setAvailableFunds(await usersClient.getAvailableFundsForUser(user?.id))
        }
    },[user, currentFunds])
    useEffect(async() => {
        if(!open){
            setNewFunds([])
        }
    },[open])
    const addFunds = async (e) =>{
        await TryActionWithCatchDialogAsync(async () => {
            for(const fund of newFunds){
                await usersClient.addFundToUser(user.id, fund.id)
            }
            setOpen(false)
            },openDialog)
    }
    return (<Dialog open={open} onClose={(e) => setOpen(false)} maxWidth='xs'>
        <DialogTitle color={COLOURS.fontColor}>Add Funds</DialogTitle>
        <DialogContent>
        <MultipleSelectChip objects={availableFunds} selectedObjects={newFunds} setSelectedObjects={setNewFunds} label={'Select Funds'}/>
        </DialogContent>
        <DialogActions> 
            <Button onClick={addFunds} sx={otusButtonStyleGreen}>Add</Button>
            </DialogActions>
      </Dialog>)
}