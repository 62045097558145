import React, { useEffect, useState } from 'react';
import LogIn from './LogIn';
import { Route, Routes } from 'react-router-dom';
import Funds from './Funds';
import FundInfo from './FundInfo';
import UserProfile from './UserProfile'
import MfaDialog from './MfaDialog';
import MarketingDocuments from './MarketingDocuments';
import AccountsWrapper from './AccountsWrapper';
import Performance from './Performance';
import FundExposures from './FundExposures';
import UsersManagement from './UsersManagement'
import ResetPasswordDialogFirstTime from './ResetPasswordDialogFirstTime';

export default function Investors({navHeight, setToken, usersClient, fundsClient
  , shareClassesClient, user, setUser, mfaNotConfirmed,setMfaNotConfirmed, funds, fund, setFund}) {
    const [isFirstView, setIsFirstView] = useState(false)
    useEffect(() =>{
      if(!user) return;
      usersClient?.getUsage().then(res=>{
          setIsFirstView(res?.length == 0)
      });
    },[user])
    if(!user) {
        return <LogIn setToken={setToken} navHeight={navHeight} usersClient={usersClient} />
      }
    return (
      <div>
      <div style={{paddingTop:navHeight}}>
      <Routes>
            <Route path='/' element={<Funds usersClient={usersClient} funds={funds} setFund={setFund}/>}/>
            <Route path="/fundInfo" element={<FundInfo fund={fund}/>}/>
            <Route path="/marketingDocuments" element={<MarketingDocuments fundsClient={fundsClient} fund={fund}/>}/>
            <Route path="/accounts" element={<AccountsWrapper fundsClient={fundsClient} fund={fund}/>}/>
            <Route path="/performance" element={<Performance fundsClient={fundsClient} fund={fund} shareClassesClient={shareClassesClient}/>}/>
            <Route path="/exposures" element={<FundExposures fundsClient={fundsClient} fund={fund}/>}/>
            <Route path="/user/profile" element={<UserProfile user={user} setUser={setUser} usersClient={usersClient}/>}/>
            <Route path="/users" element={<UsersManagement usersClient={usersClient}/>}/>
      </Routes>
      <MfaDialog open={mfaNotConfirmed} setOpen={setMfaNotConfirmed} usersClient={usersClient}/>
      <ResetPasswordDialogFirstTime open={isFirstView} setOpen={setIsFirstView}/>
      </div>
      </div>
    );
  }
