import { useState } from "react";
import CardLastUpdated from "./CardLastUpdated";
import DefaultContainer from "./DefaultContainer";
import NeedsFund from "./NeedsFund";
import ShareClassesPerformanceTable from "./ShareClassesPerformanceTable";
import ShareClassPerformanceChart from "./ShareClassPerformanceChart";
import Tracker from "./Tracker";

export default function Performance({fund, fundsClient,shareClassesClient}){
    const [currentShareClass, setCurrentShareClass] = useState(null);
    return(<NeedsFund fund={fund}><DefaultContainer>
        <Tracker page={'Performance'}/>
        <CardLastUpdated fund={fund}/>
        <br/>
        <ShareClassesPerformanceTable fund={fund} fundsClient={fundsClient} setCurrentShareClass={setCurrentShareClass}/>
          <ShareClassPerformanceChart shareClass={currentShareClass} shareClassesClient={shareClassesClient}/>
        </DefaultContainer>
        </NeedsFund>
    )

}