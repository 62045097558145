import { Avatar, Card, Grid } from "@mui/material"
import { Link, useLocation, useParams } from "react-router-dom"
import { bios, COLOURS } from "../../variables"
import HoldingPicture from '../../images/avatar.png'
import { useEffect, useState } from "react"

export default function EmployeeBio({navHeight}){
    const[bio,setBio] = useState(useLocation().state)
    const {user} = useParams()
    useEffect(()=>{
        if(!bio){
            setBio(bios.find(x=> x.name == user))
        }
    },[])
    if(!bio) return <div></div>
    return(
        <div style={{paddingTop:navHeight*1.15, backgroundColor:'white'}}>
            <Link to='/team'><p style={{marginLeft:'10vw', textDecoration:'inherit', color:COLOURS.mainColor}}>{"< Back to Team"}</p></Link>
            <Grid container padding='0 5vw 0 5vw' rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 0 }} minHeight='70vh'>
            <Grid item xs={12} sm={6} md={6} textAlign='center'>
            <img style={{ width:'100%', maxWidth:450 }} src={bio.img}/>
            </Grid>
            <Grid item xs={12} sm={6} md={6} textAlign='left'>
            <h4 style={{color:COLOURS.mainColor}}>{bio.name + " - " + bio.title}</h4>
            <br/>
            <p>{bio.info}</p>
            </Grid>
            </Grid>
            </div>
    )
}