import React from 'react';
import privacyNotice from '../regulatoryDisclosureDocs/privacyNotice.pdf'
export default function Privacy({style}) {
  return(
    <div style={style}>
    <div class="text-document container">
      <h1 style={{textAlign: "left"}} align='center'><span style={{color: '#000000'}}>Otus Capital Management&nbsp;</span></h1>
<h2>Privacy Notice</h2>
<p style={{textAlign: 'left'}}><strong style={{fontSize: "10px"}}>
  <a href={privacyNotice} target='_blank'>Open PDF</a></strong></p>
<p>09 March 2021</p>
<p><strong>INTRODUCTION</strong></p>
<p>This notice (the “Privacy Notice”) is provided by Otus Capital Management (“OCM”) which is authorized and regulated by the Financial Conduct Authority (the “FCA”). OCM provides discretionary investment management services and is authorized as an Alternative Investment Fund Manager under the Alternative Investment Fund Managers Directive.</p>
<p>Within its regulatory permissions, OCM is permitted to provide discretionary investment management services to alternative investment funds as well as provide additional ‘top up’ services, such as discretionary investment management services, to segregated managed accounts or sub-management services to other funds (e.g. UCITS funds). OCM is categorised as a Collective Portfolio Management Investment Firm (“CPMI”) and also holds the regulatory permission for managing a UCITS. OCM also operates the&nbsp;<a href="http://www.otuscapital.com/">www.otuscapital.com</a>&nbsp;website.</p>
<p>This Privacy Notice is being provided in accordance with the requirements of data privacy laws, including the EU General Data Protection Regulation 2016/679 (“GDPR”), including as applicable in the United Kingdom (“UK GDPR”), where it is supplemented by the Data Protection Act 2018). The Privacy Notice sets out OCM’s policies for the collection, use and disclosure of personal data, when you seek to invest into one of the funds managed by OCM, or if you have established a business relationship with OCM, and the choices you have associated with that data.</p>
<p>OCM will use your data primarily to perform the services that you have requested and/or to fulfill legal and regulatory requirements, such as anti-money laundering regulations. By requesting information relating to the funds which are managed by OCM, or to provide a business service to OCM, you agree to the collection and use of information in accordance with this Privacy Notice.</p>
<p><strong>DEFINITIONS</strong></p>
<p><em>Applicable Data Protection Law</em></p>
<p>“Applicable Data Protection Law” means the GDPR, the UK GDPR, the UK Data Protection Act 2018 and any national laws governing the protection of personal data as may be amended from time to time</p>
<p><em>Data</em></p>
<p>“Data” means Cookies Data, Personal Data and Usage Data.</p>
<p><em>Data Controller</em></p>
<p>“Data Controller” means a person or entity who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed.</p>
<p>For the purpose of this Privacy Policy, OCM is a Data Controller of your “Personal Data”.</p>
<p><em>Data Processor</em></p>
<p>“Data Processor” means any person or entity (other than an employee of the Data Controller) who processes the data on behalf of the Data Controller.</p>
<p><em>Data Subject</em></p>
<p>“Data Subject” means any living individual who is the subject of Personal Data</p>
<p><em>Personal Data</em></p>
<p>“Personal Data” means any information relating to a Data Subject; an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.</p>
<p><em>User</em></p>
<p>“The User” means the individual or your representative if investing in any of the funds managed by OCM. The User corresponds to the Data Subject, who is the subject of Personal Data.</p>
<p><strong>INFORMATION COLLECTION AND USE</strong></p>
<p><em>How and why OCM obtains personal information</em></p>
<p>OCM takes great care to protect personal information about you and when and how this information is used. OCM may use personal information about you to ensure a high quality service is maintained, to protect your investment, to help with the processing of transactions on your behalf, to respond to inquiries from you or your representative and, or, to fulfill legal and regulatory requirements.</p>
<p><strong>TYPES OF DATA COLLECTED</strong></p>
<p><em>Personal Data</em></p>
<p>During your dealings with OCM, we may ask you to provide us with certain Personal Data. Personal Data may include, but is not limited to:&nbsp;</p>
<ul>
<li>
<p>Email address</p>
</li>
<li>
<p>First name and last name</p>
</li>
<li>
<p>Cookies and Usage Data&nbsp;</p>
</li>
<li>
<p>Telephone Number&nbsp;</p>
</li>
<li>
<p>Work History&nbsp;</p>
</li>
<li>
<p>Meeting Notes&nbsp;</p>
</li>
</ul>
<p>OCM may collect public and non-public personal information about you from any of the following sources:</p>
<ul>
<li>
<p>You or your representative on applications or forms (for example, name address (including proofs of name and address), contact details, gender, nationality, photograph, signature, copies of identity documents, national identification number, birth date, assets and income, and tax residence information).</p>
</li>
<li>
<p>Other interactions with the Fund or service providers (for example, discussions with fund administration staff, including emails (and related data), call recordings and website usage data).</p>
</li>
<li>
<p>Information from other third-party data services (for example, to verify your identity and to better understand your product and service needs, including anti-money laundering procedures, counter-terrorist financing procedures, politically-exposed-person checks, sanctions checks, information from government or public bodies, public websites and other public sources and information received from the applicant’s advisers or from intermediaries).</p>
</li>
<li>
<p>You or your representative regarding your preferences (for example, your choice of electronic information delivery).</p>
</li>
<li>
<p>Other sources with your consent or with the consent of your representative (for example, from other institutions).</p>
</li>
</ul>
<p><em>Website&nbsp;Usage Data&nbsp;</em></p>
<p>We may also collect information on how and when our website is accessed and used ("Usage Data"). This Usage Data may include information such as your computer's Internet Protocol (“IP”) address, browser type, browser version, the pages of our website that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.&nbsp;</p>
<p><em>Tracking Cookies Data&nbsp;</em></p>
<p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.&nbsp;</p>
<p>Cookies are files with small amounts of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies that may also be used are beacons, tags, and scripts to collect and track information and to improve and analyze our service.&nbsp;</p>
<p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some parts of our website.&nbsp;</p>
<p>Examples of Cookies we use:&nbsp;</p>
<ul>
<li>
<p><strong>Session Cookies</strong>.&nbsp;We use Session Cookies to operate our website.&nbsp;</p>
</li>
<li>
<p><strong>Preference Cookies</strong>.&nbsp;We use Preference Cookies to remember your preferences and various settings.&nbsp;</p>
</li>
<li>
<p><strong>Security Cookies</strong>.&nbsp;We use Security Cookies for security purposes.&nbsp;</p>
</li>
</ul>
<p><strong>USE OF DATA</strong></p>
<p>OCM is entitled to process your Personal Data in these ways for the following reasons:</p>
<p>(a) &nbsp;&nbsp; If you are the investor, you may contact us and some processing will be necessary for the performance of that legitimate business interest, or will be done at your request prior to entering into further detailed discussions.</p>
<p>(b) &nbsp;&nbsp; Processing may be necessary to discharge a relevant legal or regulatory obligation.</p>
<p>(c) &nbsp;&nbsp; The processing will, in all cases, be necessary for the legitimate business interests of OCM such as:</p>
<p>&nbsp; &nbsp; &nbsp; &nbsp; (i) carrying out the ordinary or reasonable business activities of the OCM or other persons, or other activities previously disclosed to the Data Subject or referred to in this Privacy Notice;</p>
<p>&nbsp; &nbsp; &nbsp; &nbsp; (ii) ensuring compliance with all legal and regulatory obligations and industry standards, and preventing fraud;</p>
<p>&nbsp; &nbsp; &nbsp; &nbsp; (iii) establishing, exercising or defending legal rights or for other purposes relating to legal proceedings; and</p>
<p>&nbsp; &nbsp; &nbsp; &nbsp; (iv) ensuring the security of information systems.</p>
<p>(d) &nbsp;&nbsp; In respect of any processing of sensitive Personal Data falling within special categories, such as any Personal Data relating to the political opinions of a politically exposed person, the processing will be necessary for reasons of substantial public interest.</p>
<p class="paragraph">OCM collects Data for legitimate business reasons for various purposes that includes:&nbsp;</p>
<ul>
<li>
<p>To provide and maintain our service&nbsp;</p>
</li>
<li>
<p>To provide you with information you have requested&nbsp;</p>
</li>
<li>
<p>To notify you about changes to our service&nbsp;</p>
</li>
<li>
<p>To allow you to participate in interactive features of our Service when you choose to do so&nbsp;</p>
</li>
<li>
<p>To provide customer support&nbsp;</p>
</li>
<li>
<p>To gather analysis or valuable information so that we can improve our Service&nbsp;</p>
</li>
<li>
<p>To monitor the usage of our service&nbsp;</p>
</li>
<li>
<p>To detect, prevent and address technical issues&nbsp;</p>
</li>
<li>
<p>To provide you with news, general information about other services and events which we offer that are&nbsp;similar to&nbsp;those that you have already&nbsp;received&nbsp;or enquired about unless you have opted not to receive such information&nbsp;</p>
</li>
</ul>
<p class="ssPara1">We are entitled to use your Personal Data in these ways because:</p>
<ul>
<li>
<p>we have legal and regulatory obligations that we have to discharge;</p>
</li>
<li>
<p>we may need to in order to establish, exercise or defend our legal rights or for the purpose of legal proceedings; or</p>
</li>
<li>
<p>the use of your Personal Data as described is necessary for our legitimate business interests (or the legitimate interests of one or more of our affiliates), such as:</p>
</li>
</ul>
<p><strong>RETENTION OF DATA</strong></p>
<p>OCM will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
<p>OCM will also retain Usage Data for legitimate business reasons. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our service, or we are legally required to retain this data for longer time periods.</p>
<p><strong>TRANSFER OF PERSONAL DATA</strong></p>
<p>It is not expected that any Personal Data will be transferred by OCM to anyone who is located outside of the European Economic Area (“EEA”) and/or the United Kingdom.</p>
<p>The Personal Data that we collect from you may be transferred to, and stored at, a destination outside the EEA and/or the United Kingdom. It may also be processed by staff operating outside of the EEA and/or the United Kingdom who work for our affiliates or for one of our suppliers.</p>
<p>Where we transfer your Personal Data outside the EEA and/or the United Kingdom, we will ensure that it is protected in a manner that is consistent with how your Personal Data will be protected by us in the EEA. This can be done in a number of ways, for instance:</p>
<ul>
<li>
<p>the country that we send the Personal Data to might be approved by the European Commission and/or the United Kingdom’s Information Commissioner’s Office;</p>
</li>
<li>
<p>the recipient might have signed up to a contract based on “model contractual clauses” approved by the European Commission and/or the United Kingdom’s Information Commissioner’s Office, obliging them to protect your personal data; or</p>
</li>
<li>
<p>where the recipient is located in the US, it might be a certified member of the EU-US or UK-US Privacy Shield scheme.</p>
</li>
</ul>
<p class="ssPara1">In other circumstances, the law may permit us to otherwise transfer your Personal Data outside the EEA and/or the United Kingdom. In all cases, however, we will ensure that any transfer of your personal data is compliant with data protection law.</p>
<p>You can obtain more details of the protection given to your Personal Data when it is transferred outside the EEA and/or the United Kingdom (including a copy of the standard data protection clauses which we have entered into with recipients of your Personal Data) by contacting us in accordance with the “Contacting Us” section below.</p>
<p><strong>DISCLOSURE OF DATA</strong></p>
<p><em>Disclosure for Law Enforcement</em></p>
<p>Under certain circumstances, OCM may be required to disclose your Personal Data if required to do so by law or in response to a valid request by public authorities (e.g. a court or a government agency).</p>
<p><em>Legal Requirements</em></p>
<p>Your Personal Data may be disclosed in good faith and an understanding that such action is necessary to:</p>
<ul>
<li>
<p>Comply with a legal obligation</p>
</li>
<li>
<p>Protect and defend the rights or property of OCM</p>
</li>
<li>
<p>Prevent or investigate possible wrongdoing in connection with OCM</p>
</li>
<li>
<p>Protect the personal safety of users of OCM or the public</p>
</li>
<li>
<p>Protect against legal liability</p>
</li>
</ul>
<p><strong>SECURITY OF DATA</strong></p>
<p>The security of your Personal Data is very important to us but no method of transmission over the Internet, or method of electronic storage is 100% secure. While OCM strives to use commercially acceptable means to protect your Personal Data, it cannot guarantee its absolute security.</p>
<p><strong>YOUR RIGHTS</strong></p>
<p>OCM aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data. Please contact OCM using the details below to make any required changes.</p>
<p>If you wish to be informed of what Personal Data OCM holds about you, or if you wish that certain information is removed, please contact OCM using the details below.</p>
<p>The applicable data protection law grants you certain rights (“Information Rights”) which are summarised below:</p>
<ul>
<li>
<p>Right of access: You have the right of access to Personal Data OCM holds about or concerning you. If you would like to exercise this right you should contact us using the details below.</p>
</li>
<li>
<p>Right of rectification or erasure:&nbsp;If you feel that any Personal Data that OCM holds about you is inaccurate, you have the right to ask us to correct or rectify it. You also have a right to ask us to erase information about you where you can demonstrate that the Personal Data we hold is no longer needed by us, or if you withdraw the consent upon which our processing is based, or if you feel that we are unlawfully processing your Personal Data. Your right of rectification and erasure extends to anyone we have disclosed your personal information to and we shall take all reasonable steps to inform those with whom we have shared your Personal Data about your request for erasure.</p>
</li>
<li>
<p>Right to restriction of processing:&nbsp;You have a right to request that we refrain from processing your Personal Data where you contest its accuracy, or the processing is unlawful and you have opposed its erasure, or where we don’t need to hold your Personal Data any more but you need us to in order to establish, exercise or defend any legal claims, or we are in dispute about the legality of our processing your Personal Data.</p>
</li>
<li>
<p>Right to portability:&nbsp;You have a right to receive any Personal Data that you have provided to us in order to transfer it onto another Data Controller where the processing is based on consent and is carried out by automated means called a data portability request.</p>
</li>
<li>
<p>Right to object:&nbsp;You have a right to object to our processing of your Personal Data where the basis of the processing is our legitimate interests including but not limited to direct marketing and profiling.</p>
</li>
<li>
<p>Right to withdraw consent:&nbsp;You have the right to withdraw your consent for the processing of your Personal Data where the processing is based on consent.</p>
</li>
<li>
<p>Right of complaint:&nbsp;You have the right to lodge a complaint with OCM or a supervisory authority in the EU Member State and/or the United Kingdom of your habitual residence or place of work or place of the alleged infringement if you consider that the processing of your personal data carried out by OCM and/or the Administrator infringes the Applicable data protection law.</p>
</li>
</ul>
<p>If you would like to find out more about your rights, please contact us. Please note that we may ask you to verify your identity before responding to such requests.</p>
<p><strong>CHILDREN'S PRIVACY</strong></p>
<p>Our Service does not address anyone under the age of 18 ("Children"). We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children have provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that Personal Data from our servers.</p>
<p><strong>CHANGES TO THIS PRIVACY POLICY</strong></p>
<p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
<p>We will let you know via email, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.</p>
<p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
<p><strong>CONTACT US</strong></p>
<p>If you have any questions about this Privacy Policy, please contact us:</p>
<ul>
<li>
<p>By email:&nbsp;<a href="mailto:gdpr@otuscapital.com">gdpr@otuscapital.com</a>&nbsp;&nbsp;</p>
</li>
<li>
<p>By phone number: +44 (0)20 7227 0000</p>
</li>
<li>
<p>By mail: Otus Capital Management Limited, 29 Queen Anne’s Gate, London, SW1H 9BU, UNITED KINGDOM</p>
</li>
</ul>
</div>
</div>
  );
}