import DataTable from './DataTable'
import TableCell from '@mui/material/TableCell';
import DownloadPdfLink from './DownloadPdfLink'
import {StyledTableRow} from './tableStyles/twoColourRows'
import {distinct, TryActionWithCatchDialogAsync} from '../components/Utils'
import { useEffect, useState } from 'react';
import { useDialog } from 'react-mui-dialog';

export default function Newsletters({fund, fundsClient}){
    const [newsletters, setNewsletters] = useState(null)
    const dialog = useDialog()
    useEffect(async()=>{
      await TryActionWithCatchDialogAsync(async() => {
        setNewsletters(await fundsClient.getNewsletters(fund.id))
      },dialog.openDialog)
    },[fund])
    return(
      <DataTable columnNames={['Year', 'Q1', 'Q2', 'Q3', 'Q4' ]}>
           {newsletters?.map(x=> new Date(x)).map(x=> x.getFullYear()).filter(distinct).map(year =>{
             return (<StyledTableRow>
             <TableCell align='center'>{year}</TableCell>
             {[1,2,3,4].map( quarter =>
             {
              const newsletter = newsletters.map(x=> new Date(x)).find(x=> 
                {
                  const y = x.getFullYear();
                  const m = x.getMonth();
                  return x.getFullYear() == year && x.getMonth() == quarter*3 -1
                })
              return <TableCell align='center'>
                {newsletter ?
                (
                  <DownloadPdfLink arrayBufferPromise={async() => await fundsClient
                  .getNewsletter(fund.id,(newsletter.getMonth()+1)/3,newsletter.getFullYear())} filename='newsletter.pdf' >
                  PDF
                  </DownloadPdfLink>
                ) 
                : ''
                }
                </TableCell>
             }
             )}
              </StyledTableRow>
             )
           })}
    </DataTable>
    )
}