import BaseClient from "./base";
export default class UsersClient extends BaseClient{
    constructor(tokenModel, setToken){
        super(tokenModel,setToken)
        this.basePath = 'users'
    }
    async loginUser(credentials) {
          const response = await this.request({resource: 'oauth/token', method: 'POST'
        , body: {
            email: credentials.emailAddress,
            password: encodeURIComponent(credentials.password)
          }
          , headers: { 'Content-Type' : 'application/json' } 
         })
         return response
      }
    async getFunds() {
      return await this.request({resource: this.basePath + "/user/funds", method: 'GET' })
    }
    async getFundsForUser(userId) {
      return await this.request({resource: this.basePath + "/"+ userId+"/funds", method: 'GET' })
    }
    async get() {
      return await this.request({resource: this.basePath + "/user", method: 'GET' })
    }
    async getUsers() {
      return await this.request({resource: this.basePath + "", method: 'GET' })
    }
    async getUsage() {
      return await this.request({resource: this.basePath + "/user/usage", method: 'GET' })
    }
    async Update(user) {
      await this.request({resource: this.basePath + "/user", method: 'PUT'
    , body: user })
    }
    async updatePasswordForCurrentUser(password) {
      return await this.request({resource: this.basePath + '/password/' + encodeURIComponent(password)
      , method: 'PUT'
      , responseIsText:true  })
    }
    async sendLogInRequest(values) {
      await this.request({resource: this.basePath + "/logInRequest", method: 'POST'
    , body: values, headers: { 'Content-Type' : 'application/json' }, responseIsText:true  })
    }
    async sendResetPasswordCodeToEmail(email) {
      await this.request({resource: this.basePath + "/resetPasswordCode/" + email, method: 'POST'
      , headers: { 'Content-Type' : 'application/json' },responseIsText:true  })
    }
    async checkResetCode(email, resetCode) {
      return await this.request({resource: this.basePath + "/checkResetCode/email=" + email+"&resetCode=" + resetCode, method: 'GET'
      , headers: { 'Content-Type' : 'application/json' },responseIsText:true  })
    }
    async updatePassword(email, password, resetCode) {
      return await this.request({resource: this.basePath + "/updatePassword/email=" + email+"&password=" + encodeURIComponent(password) +"&resetCode=" + resetCode, method: 'POST'
      , headers: { 'Content-Type' : 'application/json' },responseIsText:true  })
    }
    async updateMfa() {
        return await this.request({resource: this.basePath + "/user/mfa", method: 'PUT',responseIsText:true })
      }
    async checkMfaCode(inputCode) {
      return await this.request({resource: this.basePath + "/user/mfa/" + inputCode, method: 'GET',responseIsText:true })
    }
    async getEnableMfaQrCode() {
      return await this.request({resource: this.basePath + "/user/mfa/qrCode", method: 'GET',responseIsText:true })
    }
    async removeFund(userId, fundId){
      return await this.request({resource: this.basePath + "/" + userId + "/funds/" + fundId, method: 'DELETE',responseIsText:true })
    }
    async delete(userId){
      return await this.request({resource: this.basePath + "/" + userId, method: 'DELETE',responseIsText:true })
    }
    async create(user){
      await this.request({resource: this.basePath, method: 'POST', body: user,responseIsText:true  })
    }
    async updateUser(user){
      await this.request({resource: this.basePath, method: 'PUT', body: user,responseIsText:true  })
    }
    async checkEmailAvailable(email) {
      return await this.request({resource: this.basePath + "/checkEmailAvailable/" + email
      , method: 'GET',responseIsText:true }) == 'true'
    }
    async addFundToUser(userId, fundId){
      await this.request({resource: this.basePath +"/"+ userId +"/funds/"+ fundId, method: 'POST', responseIsText:true  })
    }
    async getAvailableFundsForUser(userId){
      return await this.request({resource: this.basePath +"/"+ userId + "/availableFunds"
      , method: 'GET' })
    }
    async log(userLog){
      await this.request({resource: this.basePath +"/user/log", body:userLog, method: 'POST', responseIsText:true  })
    }
}
