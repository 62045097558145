import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, TextField } from "@mui/material"
import { useState } from "react";
import { otusButtonStyleGreen, inputStyleColour } from "../variables"
import { openAlertDialog } from './Utils';
import { useDialog } from "react-mui-dialog";
import { Link } from "react-router-dom";
import ResetPasswordDialog from "./ResetPasswordDialog";
import {COLOURS} from '../variables'
import { trackPromise } from "react-promise-tracker";
import useIsSmalllScreen from './App/useIsSmallScreen'

const LogInSection = ({handleLogin, usersClient}) =>{

  const isSmallScreen = useIsSmalllScreen()
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({
    email: '',
    password: '',
    keepSignedIn: false
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  
  return (
    <Box display='flex' flexDirection={'row'} alignItems='center' justifyContent={isSmallScreen ? 'center': 'flex-end'}>
    <Box textAlign={'left'}>
    <h2>INVESTOR LOGIN</h2>
    <br/>
    <br/>
    <FormControl height='12vh' sx={{display:'flex'}}>
    <TextField autoComplete="on" label="Email" type='email' size='small' sx={inputStyleColour(COLOURS.fontColor)} value={values.email} onChange={handleChange('email')} />
    <br/>
    <TextField 
    label="Password" type='password' size='small' sx={inputStyleColour(COLOURS.fontColor)} value={values.password} onChange={handleChange('password')} />
    <br/>
    <FormControlLabel sx={{color:COLOURS.fontColor}} control={<Checkbox sx={{color:COLOURS.fontColor}} labelStyle={{color: 'white'}} onChange={handleChange('keepSignedIn')}/>} label="Keep me signed in" />
      <br/>
      </FormControl>
      <Box display='flex' flexDirection={'row'} alignItems='center' justifyContent={isSmallScreen ? 'center': 'flex-end'}></Box>
    <Button sx={otusButtonStyleGreen} onClick={(e) => setOpen(true)}>RESET PASSWORD</Button>
    <Button sx={{...otusButtonStyleGreen, float:'right'}} variant='outlined' onClick={(e) => handleLogin(values)}>LOGIN</Button>
    <ResetPasswordDialog open={open} defaultEmail={values.email} setOpen={setOpen} usersClient={usersClient}/>
    </Box>
    </Box>
  )
}
const RequestAccessSection = () =>{
  const isSmallScreen = useIsSmalllScreen()
  return (
    <Box display='flex' flexDirection={'row'} justifyContent={isSmallScreen ? 'center': 'flex-start'}>
    <Box textAlign={isSmallScreen ? 'center' : 'left'} padding={!isSmallScreen ? '0' : '0 5vw'}
     maxWidth={500}>
    <h2>REQUEST ACCESS</h2>
    <br/>
    <br/>
    <p>If you would like to access our website and don’t already have a login, please request one by clicking the button below</p>
    <Link style={{textDecoration:'none'}} to='/requestAccess'><Button sx={otusButtonStyleGreen} variant='outlined'>REQUEST ACCESS</Button></Link>
    </Box>
    </Box>
  )
}


export default function LogIn({setToken, navHeight, usersClient}){
  const {openDialog} = useDialog();
  const handleLogin = async ({email,password,keepSignedIn}) =>{
    await trackPromise(usersClient.loginUser({
      emailAddress: email,
      password
    })
    .then(res =>{
      if(res){
        setToken({token: res.token, refreshToken: res.refreshToken, keepSignedIn:keepSignedIn});
      }
      else throw '401'
    }).catch(error=>{
      let message = error == '401' ?'Login details not valid' : 'unable to reach server'
      openAlertDialog('Error', message, openDialog)
      setToken(null);
    }));
  }
return (
    <div style={{paddingTop:navHeight, display:'flex', justifyContent:'center', alignItems:'center', flex:1, backgroundColor:'white', color:COLOURS.fontColor
    , paddingTop:'20vh', paddingBottom:'10vh'}}>
    <Grid container rowSpacing={{xs:5}} columnSpacing={{ xs: 1, sm: 2, md: 20 }}>
    <Grid item xs={12} sm={6} md={6}> <LogInSection handleLogin={handleLogin} usersClient={usersClient}/> </Grid>
    <Grid item xs={12} sm={6} md={6}><RequestAccessSection/> </Grid>
  </Grid>
    </div>
)}