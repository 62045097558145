import { useCallback } from 'react'
import { useContext, useEffect } from 'react'
import {TrackContext} from './App/App'

export default function Tracker ({page}){
    const {track,user, fund} = useContext(TrackContext)
    useEffect(() => {
      if(user){
        track(page)
      }
    },[fund])
    return null
  }