import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { COLOURS, inputStyleColour } from '../variables';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectChip({label, objects, selectedObjects=[], setSelectedObjects, displayMember='name'}) {


  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedObjects(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <div>
      <FormControl sx={{...inputStyleColour(COLOURS.fontColor), m: 1, width:'400px' }}>
        <InputLabel >{label}</InputLabel>
        <Select
          multiple
          value={selectedObjects}
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected?.map((value) => (
                <Chip key={value} label={value[displayMember]} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {objects.map((obj) => (
            <MenuItem
                sx={{color:COLOURS.fontColor}}
              key={obj}
              value={obj}
            >
              {obj[displayMember]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
