import DataTable from './DataTable'
import TableCell from '@mui/material/TableCell';
import {StyledTableRow} from './tableStyles/twoColourRows'
import { TableRow } from '@mui/material';
import {distinct, TryActionWithCatchDialogAsync} from '../components/Utils'
import DownloadPdfLink  from './DownloadPdfLink'
import { useState, useEffect } from 'react';
import { useDialog } from 'react-mui-dialog';

export default function Snapshots({fund, fundsClient}){
    const [snapshots, setSnapshots] = useState()
    const dialog = useDialog()
    useEffect(async()=>{
      await TryActionWithCatchDialogAsync(async() => {
        setSnapshots(await fundsClient.getSnapshots(fund.id))
      },dialog.openDialog)
    },[fund])

    return(
      <DataTable columnNames={['Year', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']}>
           {
            snapshots?.map(x=> new Date(x)).map(x=> x.getFullYear()).filter(distinct).map(year =>{
             return (<StyledTableRow>
             <TableCell align='center'>{year}</TableCell>
             {[0,1,2,3,4,5,6,7,8,9,10,11].map( month =>
             {
              const snapshot = snapshots.map(x=> new Date(x)).find(x=> {return x.getFullYear() == year && x.getMonth() == month})
              return <TableCell align='center'>
                {snapshot ? (
                <DownloadPdfLink arrayBufferPromise={async() => await fundsClient.getSnapshot(fund.id,snapshot.getMonth() + 1,snapshot.getFullYear())} filename='snapshot.pdf' >
                PDF
                </DownloadPdfLink>
                ) : ''}
                </TableCell>
             }
             )}
              </StyledTableRow>
             )
           })}
    </DataTable>
    )
}