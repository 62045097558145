import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


export default function DataTable({style, children, columnNames}){

    return <TableContainer component={Paper} sx={style}>
    <Table>
      <TableHead>
        <TableRow>
            {columnNames.map((col)=>{
            return <TableCell align='center' style={{fontWeight:'bold'}}>{col}</TableCell>
            })}
        </TableRow>
      </TableHead>
      <TableBody>
      {children}
      </TableBody>
      </Table>
  </TableContainer>
}