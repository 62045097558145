import { useEffect, useState } from 'react';

export default function useToken() {
  const getToken = () => {
      const tokenString = localStorage.getItem('token')
      const userToken = JSON.parse(tokenString);
      return userToken
  } 
  const saveToken = userToken => {
    localStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken);
  };
  const [tokenModel, setToken] = useState(getToken());
  useEffect(()=>{ 
    window.onbeforeunload = function (e) {
    window.onunload = function () {
      if(tokenModel?.keepSignedIn == false){
        localStorage.removeItem('token')
      }
    }
    return undefined;
}    
},[tokenModel])
  return {
    setToken: saveToken,
    tokenModel
  }
}