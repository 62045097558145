import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {inputStyleColour} from '../variables'
export default function FundComboBox({funds, fund, setFund}) {


  const handleChange = (event) => {
    setFund(event.target.value);
  };
  return (
    <FormControl sx={{...inputStyleColour('darkgray'), m: 1, minWidth: 130}} size="small">
      <InputLabel>Select Fund</InputLabel>
      <Select
        value={fund??""}
        label="Select Fund"
        onChange={handleChange}
      >
        {funds?.map((tempFund)=>{
            return <MenuItem value={tempFund}>{tempFund.name}</MenuItem>
        })}
        
      </Select>
    </FormControl>
  );
}