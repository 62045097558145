import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, Button } from '@mui/material';
import { useState, useEffect } from 'react';
import { useDialog } from 'react-mui-dialog';
import { COLOURS, inputStyleColour, otusButtonStyleGreen } from '../variables';
import { TryActionWithCatchDialogAsync, openAlertDialog } from './Utils';
export default function ResetPasswordDialog({open, defaultEmail, setOpen, usersClient}){
    const {openDialog} = useDialog()
    const [values, setValues] = useState({
      resetCode:'',
      password:'',
      confirmPassword:'',
      email:defaultEmail,
    })
    const [codeConfirmed,setCodeConfirmed] = useState(false)
    const [codeSent, setCodeSent] = useState(false)
    useEffect(() =>
    {
      setValues(prev => 
        {
          return {...prev, email:defaultEmail}
        });
    },[defaultEmail])
    const handleChange = (prop) => (event) => {
      setValues({ ...values, [prop]: event.target.value });
    };
    const sendCode = async() => {
      await TryActionWithCatchDialogAsync(async()=>{
        try{
          await usersClient.sendResetPasswordCodeToEmail(values.email)
        }
        catch(ex){
            if(ex != 404) throw ex
        }
        setCodeSent(true)
      },openDialog,(error) => error =='404' ? 'The email was not found' : null)
    }
    const checkResetCode = async ()=>{
      await TryActionWithCatchDialogAsync(async()=>{
        const codeSent = await usersClient.checkResetCode(values.email, values.resetCode)
        if(codeSent=='true') {
          setCodeConfirmed(true)
        }
        else {
          throw '403'
        }
      },openDialog,(error) => error =='403' ? 'Reset Code Invalid or Expired' : null)
    }
    const updatePassword = async() => {
      await TryActionWithCatchDialogAsync(async()=>{
        if(values.password != values.confirmPassword){
          openAlertDialog("Error","Password and Confirm Password are Different", openDialog)
        }
        else if(values.password.length < 8){
          openAlertDialog("Error","Password must be at least 8 characters long", openDialog)
        }
        else{
          await usersClient.updatePassword(values.email,values.password, values.resetCode)
          openAlertDialog("Success","Password Updated Successfully", openDialog)
          setOpen(false)
        }
      },openDialog)
      };
    const handleClose = (e,reason) =>{
      if (reason && reason == "backdropClick")  return;
      setOpen(false)
      setCodeSent(false)
    }



    if(!codeSent) return(
      <Dialog open={open} onClose={(e) => setOpen(false)}>
      <DialogTitle color={COLOURS.fontColor}>Enter Email Address</DialogTitle>
      <DialogContent>
        <TextField
              autoFocus
              margin="dense"
              label="Email"
              type="email"
              onChange={handleChange('email')}
              value={values.email}
            sx={inputStyleColour(COLOURS.fontColor)}
            fullWidth
              variant="standard"
            />
      </DialogContent>
      <DialogActions> <Button  sx={otusButtonStyleGreen} onClick={sendCode}>Send Code</Button></DialogActions>
    </Dialog>)
    if(codeConfirmed) return(
    <Dialog open={open} onClose={handleClose}>
    <DialogTitle color={COLOURS.fontColor}>Reset Password</DialogTitle>
    <DialogContent>
      <TextField
            autoFocus
            margin="dense"
            label="New Password"
            type="password"
            onChange={handleChange('password')}
            value={values.password}
            fullWidth
            sx={inputStyleColour(COLOURS.fontColor)}
            variant="standard"
          />
          <TextField
            sx={inputStyleColour(COLOURS.fontColor)}
            margin="dense"
            label="Confirm New Password"
            type="password"
            onChange={handleChange('confirmPassword')}
            value={values.confirmPassword}
            fullWidth
            variant="standard"
          />
    </DialogContent>
    <DialogActions> <Button  sx={otusButtonStyleGreen} onClick={updatePassword}>Reset</Button></DialogActions>
  </Dialog>)
    return (<Dialog open={open} onClose={handleClose}>
    <DialogContent>
      <DialogContentText  color={COLOURS.fontColor}>
        Please, enter the 6 digit code that has been sent to {values.email}.
      </DialogContentText>
      <TextField
            autoFocus
            margin="dense"
            label="Reset Code"
            type="text"
            onChange={handleChange('resetCode')}
            value={values.resetCode}
            fullWidth
            sx={inputStyleColour(COLOURS.fontColor)}
            variant="standard"
          />
    </DialogContent>
    <DialogActions><Button sx={otusButtonStyleGreen} onClick={checkResetCode}>Check Code</Button></DialogActions>
  </Dialog>)
}