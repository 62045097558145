import { Typography } from '@mui/material';

export default function FundDescription({fund}){
    return (
        <div style={{padding:'3vh 3vw 3vh 3vw', borderRadius:10, backgroundColor:'white'}}>
        <h2 style={{textAlign:'center'}}>{fund.name}</h2>
        <hr/>
        {fund.description.split('\n').map(str => <p>{str}</p>)}
        <br/>
        <br/>
        </div>
    )
}