import { usePromiseTracker } from "react-promise-tracker";
import { ThreeDots } from 'react-loader-spinner'
import { COLOURS } from "../variables";
export default function LoadingIndicator ({trackerArea, innerRef}) {
    const { promiseInProgress } = usePromiseTracker({area:trackerArea});
    const defaultStyle = {
      width:"100%",
      height:'100%',
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position:'absolute'
    }
    return ( promiseInProgress && 
      <div
        style={innerRef?.current == undefined ? defaultStyle : {...defaultStyle
          , width:innerRef.current.offsetWidth, height: innerRef.current.offsetHeight}}
      >
        <ThreeDots color={COLOURS.indicatorColor} height="60" width="60" />
      </div>
      );  
     }