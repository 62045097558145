import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import { COLOURS, otusButtonStyleGreen } from '../variables';
export default function ConfirmDialog({open, setOpen, handleConfirm, content}){
  const handleClose = (e) => setOpen(false)
    return(
        <Dialog open={open} onClose={handleClose}>
        <DialogTitle color={COLOURS.fontColor}>Confirm</DialogTitle>
        <DialogContent>
            <p style={{color: COLOURS.fontColor}}>{content}</p>
        </DialogContent>
        <DialogActions> 
            <Button onClick={handleClose}  sx={otusButtonStyleGreen}>Cancel</Button>
            <Button onClick={(e) =>{
              if(handleConfirm) handleConfirm()
              handleClose(e)
            }}  sx={otusButtonStyleGreen}>OK</Button>
            </DialogActions>
      </Dialog>)
}