import DefaultContainer from "./DefaultContainer"
import FundDescription from "./FundDescription"
import Prospectus from "./Prospectus"
import NeedsFund from "./NeedsFund"
import Tracker from "./Tracker"

export default function FundInfo({fund}){
    return (<NeedsFund fund={fund}><DefaultContainer>
        <Tracker page={'Fund Info'}/>
        <FundDescription fund={fund}/>
        <br/>
        <br/>
        {fund?.id == 7 ? <Prospectus fund={fund}/> : null}
    </DefaultContainer></NeedsFund>)
}