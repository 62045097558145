import { TextField } from '@mui/material';
import { useState } from 'react';


export default function MyDatePicker({dt, onChange,label, style}) {
    const [date, setDate] = useState(dt?.toISOString().substring(0,10));
    return (
        <TextField
        id="date"
        label={label}
        type="date"
        size='small'
        onChange={(e) =>{
            setDate(e.target.value)
            const dt = new Date(e.target.value);
            onChange(e.target.value ? dt : null);
        }}
        value={date}
        sx={{ ...style,width: 150 }}
        InputLabelProps={{
            shrink: true,
          }}
      />
    );
  };