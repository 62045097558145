import { useEffect, useState } from "react";
import { Waypoint } from "react-waypoint";
import { useSpring, animated } from "react-spring";


const ZoomOnView = ({ children, delay=500, side='left', topOffset, bottomOffset }) => {
  const [inView, setInview] = useState(false);
  
  const transition = useSpring({
    delay: delay,
    marginLeft: side=='left' ?  (!inView ? -1000 : 0) : 0,
    marginRight: side=='right' ?  (!inView ? -1000 : 0) : 0,
    opacity: !inView ? 0 : 1,
  });
  return (
    <Waypoint onEnter={() =>
    {
      setInview(true)
    } } topOffset={topOffset} bottomOffset={bottomOffset}>
      <animated.div style={transition}>
        {children}
      </animated.div>
    </Waypoint>
  );
};

export default ZoomOnView;
