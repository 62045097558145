import DataTable from "./DataTable";
import { StyledTableRow } from "./tableStyles/twoColourRows";
import { PnlTableCell } from "./tableStyles/PnlTableCell";
import { TableCell, Box } from "@mui/material";
import { useEffect, useState } from "react";
import SourceDisclosureBlock from "./SourceDisclosureBlock";
import NeedsFund from "./NeedsFund";
import DefaultContainer from "./DefaultContainer";
import MyDataGrid from "./MyDataGrid";
import clsx from 'clsx'
import Tracker from "./Tracker";
import CardLastUpdated from "./CardLastUpdated";
const Stats = ({fund}) =>{
return (
    <DataTable columnNames={['Longs (%)', 'Shorts (%)', 'Net Market Exposure* (%)', 'Number of Positions' ]}
       containerStyle={{ marginTop:'2vh', opacity:'0.9'}}>
            <StyledTableRow>
           <PnlTableCell align='center'>{fund.longExposure*100}</PnlTableCell>
           <PnlTableCell align='center'>{fund.shortExposure*100}</PnlTableCell>
           <PnlTableCell align='center'>{fund.nme*100}</PnlTableCell>
           <PnlTableCell align='center'>{fund.numberOfPositions}</PnlTableCell>
            </StyledTableRow>
  </DataTable>)
}
const Exposures = ({grouping, items}) =>{
    const gridFormat = (params) => {
        if (params.value == null)return '';
        const valueFormatted = Number(params.value * 100).toLocaleString();
        return valueFormatted;
      }
    const positiveNegativeClass = (params) => {
        if (params.value == null) {
          return '';
        }
        return clsx('positive-negative', {
          negative: params.value < 0,
          positive: params.value > 0,
        });
      }
    const style =
        {
            height:'500px'
            , backgroundColor:'white'
            , '& .positive-negative.negative': {
                color: 'red',
              },
              '& .positive-negative.positive': {
                color: 'blue',
              }
        }
    return (
        <Box width='100%'>
            <h3>{grouping}</h3>
            <MyDataGrid style={style} columns={
                [
                    {field:'name', headerName:grouping},
                    {field:'gme', headerName:'Gross Market Exposure (%)', valueFormatter:gridFormat, type:'number', cellClassName:positiveNegativeClass},
                    {field:'nme', headerName:'Net Market Exposure (%)', valueFormatter:gridFormat, type:'number',cellClassName:positiveNegativeClass}
                ]}
            rows={grouping == 'Liquidity (days)' || grouping == 'Market Cap (EUR M)' ? 
            items.map((item,i) => {return {...item, nme:item.nme, gme:item.gme, id:i}}).sort((a,b) => {
              const first = a.name.split("-")[0]
              const second = b.name.split("-")[0]
            return b.name.indexOf('>') >=0 ? -100 : first-second
            })
            : items.map((item,i) => {return {...item, nme:item.nme, gme:item.gme, id:i}})}/>
      </Box>)
    }
export default function FundExposures({fund, fundsClient}){
    const [countries, setCountries] = useState([])
    const [sectors, setSectors] = useState([])
    const [liquidity, setLiquidity] = useState([])
    const [marketCap, setMarketCap] = useState([])
    useEffect(async()=>setCountries(await fundsClient.getCountryExposures(fund.id)),[fund])
    useEffect(async()=>setSectors(await fundsClient.getSectorExposures(fund.id)),[fund])
    useEffect(async()=>setLiquidity(await fundsClient.getLiquidityExposures(fund.id)),[fund])
    useEffect(async()=>setMarketCap(await fundsClient.getMarketCapExposures(fund.id)),[fund])
    return (
        <NeedsFund fund={fund}><DefaultContainer style={{color:'white'}}>
          <Tracker page={'Exposures'}/>
        <h2>Exposures</h2>
        <hr/>
        <CardLastUpdated fund={fund}/>
        <br/>
        <Stats fund ={fund}/>
        <span>* Includes Delta Adjustment on Options</span>
        <br/>
        <Exposures items={countries} grouping={'Country'}/>
        <br/>
        <Exposures items={sectors} grouping={'Sector'}/>
        <br/>
        <Exposures items={liquidity} grouping={'Liquidity (days)'}/>
        <br/>
        <Exposures items={marketCap} grouping={'Market Cap (EUR M)'}/>
        <br/>
        <SourceDisclosureBlock source='Otus Capital Management'/>
        </DefaultContainer></NeedsFund>
    )
}