import { Box } from "@mui/material"
import { useState, useEffect } from "react"
import { trackPromise } from "react-promise-tracker"
import TimeSeriesChart from './TimeSeriesChart'
import UnableToRetrieveData from "./UnableToRetrieveData"
import { createDateAsUTC } from "./Utils"

export default function ShareClassPerformanceChart({shareClass, shareClassesClient}){
    const[shareClassValuations,setShareClassValuations] = useState()
    useEffect(async ()=>{
        if(shareClass){
          setShareClassValuations(await trackPromise(shareClassesClient.getValuations(shareClass.id),'timeseries-chart'));
      }
      },[shareClass])
      if(!shareClass) return <UnableToRetrieveData/>
    return (
    <Box backgroundColor='white' marginTop='3vh' padding='0 5px'>
    <TimeSeriesChart title={shareClass.name} name='performance' xAxisTitle='NAV'
    data = {shareClassValuations?.map(val => ({x: createDateAsUTC(new Date(val.date)), y:val.nav}))}
    />
    </Box>
    )
}