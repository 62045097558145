import React, { createContext } from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Investors from '../Investors';
import AboutUs from '../PublicPage/AboutUs';
import Footer from '../footer/Footer';
import Navbar from '../Navbar';
import { useState, useEffect } from 'react'
import UsersClient from '../../clients/users'
import FundsClient from '../../clients/funds'
import ShareclassesClient from '../../clients/shareClasses';
import useUser from './useUser'
import useToken from './useToken';
import Privacy from '../Privacy';
import TermsAndConditions from '../TermsAndConditions';
import Team from '../PublicPage/Team';
import ContactUs from '../PublicPage/ContactUs'
import Strategies from '../PublicPage/Strategies'
import Legal from '../PublicPage/Legal';
import RegulatoryDisclosures from '../PublicPage/RegulatoryDisclosures';
import RequestAccess from '../PublicPage/RequestAccess';
import useMfa from './useMfa';
import LoadingIndicator from '../LoadingIndicator';
import useTracker from './useTracker';
import EmployeeBio from '../PublicPage/EmployeeBio';
import { trackPromise } from 'react-promise-tracker';
const publicNavItems = [
  { title:'ABOUT US', link:'/' },
  { title:'TEAM', link:'/team' },
  { title:'STRATEGIES', link:'/strategies' },
  { title:'INVESTOR LOGIN', link:'/investors' },
  { title:'CONTACT US', link:'/contact' },
]
const investorNavItems = [
  { title:'HOME', link:'/investors'},
  { title:'PERFORMANCE', link:'/investors/performance', needsFund:true},
  { title:'EXPOSURES', link:'/investors/exposures', needsFund:true},
  { title:'FUND INFO', link:'/investors/fundInfo', needsFund:true},
  { title:'SNAPSHOTS/NEWSLETTERS', link:'/investors/marketingDocuments', needsFund:true},
  { title:'ACCOUNTS', link:'/investors/accounts', needsFund:true},
]

export const TrackContext = createContext();
export const UsersContext = createContext();


function App() {
  
  const {tokenModel, setToken} = useToken();
  const usersClient = new UsersClient(tokenModel, setToken)
  const fundsClient = new FundsClient(tokenModel, setToken)
  const shareClassesClient = new ShareclassesClient(tokenModel, setToken)
  const {user, setUser} = useUser(usersClient);
  const [funds, setFunds] = useState();
  const [fund, setFund] = useState();
  const [navHeight, setNavHeight] = useState()
  const {mfaNotConfirmed, setMfaNotConfirmed} = useMfa(user)
  const tracker = useTracker(user, usersClient, fund)
  
  useEffect(async () => {
    try{
      if(tokenModel){
        const user = await trackPromise(usersClient.get())
        setUser(user)
      }
    }catch(err){
      setToken(null)
    }
  },[tokenModel])
  useEffect(async ()=>{
    if(user){
      const userFunds = await trackPromise(usersClient.getFunds())
      setFunds(userFunds)
    } 
  },[user])
  useEffect(async ()=>{
    if(funds?.length > 0){
      setFund(funds[0])
    } 
  },[funds])
    return (
      <TrackContext.Provider value={tracker}>
      <UsersContext.Provider value={{usersClient, user}}>
        {
          !user? <Navbar setNavHeight={setNavHeight} items={publicNavItems}/> 
          : (
            <Routes>
                  <Route path="/*" element={<Navbar setNavHeight={setNavHeight} items={publicNavItems}/>}/>
                  <Route path="/investors/*"
                  element={<Navbar setToken={setToken} setMfa={setMfaNotConfirmed}
                   user={user} setNavHeight={setNavHeight} 
                  items={investorNavItems} 
                  setUser={setUser}
                  funds={funds} setFund={setFund} fund={fund}/>}></Route>
            </Routes>
          )
        }
      <LoadingIndicator/>
      <Routes>
            <Route path="/" element={<AboutUs navHeight={navHeight}/>}/>
            <Route path="/team" element={<Team navHeight={navHeight}/>}/>
            <Route path="/team/:user" element={<EmployeeBio navHeight={navHeight}/>}/>
            <Route path="/contact" element={<ContactUs navHeight={navHeight}/>}/>
            <Route path="/strategies" element={<Strategies navHeight={navHeight}/>}/>
            <Route path="/requestAccess" element={<RequestAccess navHeight={navHeight} usersClient={usersClient}/>}/>
            <Route path="/investors/*" 
            element={<Investors navHeight={navHeight} setToken={setToken}
            usersClient={usersClient} fundsClient={fundsClient} shareClassesClient={shareClassesClient} 
            user={user} setUser={setUser} mfaNotConfirmed={mfaNotConfirmed} setMfaNotConfirmed={setMfaNotConfirmed}
            funds={funds} fund={fund} setFund={setFund}/>}/>
          <Route path="/investors/privacy" element={<Privacy  style={{paddingTop:navHeight}}/>}/>
          <Route path="/privacy" element={<Privacy  style={{paddingTop:navHeight}}/>}/>
          <Route path="/termsAndConditions" element={<TermsAndConditions  style={{paddingTop:navHeight}}/>}/>
          <Route path="/investors/termsAndConditions" element={<TermsAndConditions  style={{paddingTop:navHeight}}/>}/>
          <Route path="/legal" element={<Legal  style={{paddingTop:navHeight}}/>}/>
          <Route path="/investors/legal" element={<Legal  style={{paddingTop:navHeight}}/>}/>
            <Route path="/regulatoryDisclosures" element={<RegulatoryDisclosures  style={{paddingTop:navHeight}}/>}/>
            <Route path="/investors/regulatoryDisclosures" element={<RegulatoryDisclosures  style={{paddingTop:navHeight}}/>}/>
      </Routes>
      <Footer/>
      </UsersContext.Provider>
      </TrackContext.Provider>
    );
  }

export default App;