import { Card, CardContent } from "@mui/material"
import {COLOURS} from '../variables'

export default function SourceDisclosureBlock({source}){
    return(
        
    <Card sx={{ backgroundColor: 'white', color: 'black', padding:'8px 8px' }}>
            <span style={{fontStyle:'italic'}}>{'Source: '+source}</span>
  </Card>
    )
}