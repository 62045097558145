import { Box, Grid } from "@mui/material"
import { keys } from "../../variables"
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import {COLOURS} from '../../variables'
import Tracker from "../Tracker";
export default function ContactUs({navHeight}){
    return (
        <div style={{paddingTop:  navHeight + 40, color:COLOURS.fontColor, backgroundColor:'white'}}>
        <Tracker page='Contact Us'/>
        <Grid container rowSpacing={{xs:10}} margin='0 0 40px 0' columnSpacing={{ xs: 1, sm: 2, md: 3 }} alignItems='center'>
        <Grid item xs={12} sm={6} md={6}>
        <Box textAlign={'left'} padding='0vw 10vw 0 10vw'>
        <h5>29 Queen Anne's Gate <br/><br/>London SW1H 9BU <br/><br/>UNITED KINGDOM</h5>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Box textAlign={'left'} padding='0vw 10vw 0 10vw' sx={{overflowWrap:'break-word'}} >
        <h5>
            <LocalPhoneIcon/>&nbsp;+44 (0) 2072270000 
            <br/><br/>
            <EmailIcon/>&nbsp;<a href="mailto:clientservices@otuscapital.com">clientservices@otuscapital.com</a>
            <br/><br/>
            <EmailIcon/>&nbsp;<a href="mailto:info@otuscapital.com">info@otuscapital.com</a>
            </h5>
          </Box>
        </Grid>
      </Grid>
            <iframe
            style={{border:0, opacity:1}}
            allowfullscreen
            referrerpolicy="no-referrer-when-downgrade"
            width='100%'
            height='500px'
            src={"https://www.google.com/maps/embed/v1/place?key="+keys.googleMaps+"&q=Otus+Capital+Management,London"}>
            </iframe>
        </div>
    )
}