import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, TextField } from '@mui/material';
import { COLOURS, inputStyleColour, otusButtonStyleGreen } from '../variables';
import { useState } from 'react';
import { openAlertDialog, TryActionWithCatchDialogAsync } from './Utils';
import { useDialog } from 'react-mui-dialog';
import { useContext } from 'react';
import { UsersContext } from './App/App';
export default function ResetPasswordDialogFirstTime({open, setOpen}){

    const {openDialog} = useDialog()
    const {usersClient} = useContext(UsersContext)
    const updatePassword = async () => {
        await TryActionWithCatchDialogAsync(async () =>{
            if(values.password.length < 8) {
                throw 'Password must be at least 8 characters'
            }
            else if (values.password != values.resetPassword){
                throw 'Confirm password does not match password'
            }
            handleClose()
            await usersClient.updatePasswordForCurrentUser(values.password)
            openAlertDialog("Password Updated Successfully","", openDialog)
        }, openDialog)
    }
    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick")  return;
        setOpen(false)
      }
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
      };
    const [values, setValues] = useState({
        password:'',
        resetPassword:'',
    })
  return(
    <Dialog open={open} onClose={handleClose}>
    <DialogTitle color={COLOURS.fontColor}>Set Up Password</DialogTitle>
    <DialogContent>
      <TextField
            autoFocus
            margin="dense"
            label="Password"
            type="password"
            onChange={handleChange('password')}
            value={values.password}
            fullWidth
            sx={inputStyleColour(COLOURS.fontColor)}
            variant="standard"
          />
          <TextField
            sx={inputStyleColour(COLOURS.fontColor)}
            margin="dense"
            label="Confirm Password"
            type="password"
            onChange={handleChange('resetPassword')}
            value={values.confirmPassword}
            fullWidth
            variant="standard"
          />
    </DialogContent>
    <DialogActions> <Button  sx={otusButtonStyleGreen} onClick={updatePassword}>Reset</Button></DialogActions>
  </Dialog>)
}