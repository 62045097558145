import BaseClient from "./base";

export default class ShareclassesClient extends BaseClient{
    constructor(tokenModel, setToken){
        super(tokenModel,setToken)
        this.basePath = 'shareClasses'
    }
    async getValuations(id) {
        return await this.request({resource: this.basePath + "/"+id +'/historicalValuations', method: 'GET'})
      }
}
