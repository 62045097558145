import DataTable from './DataTable'
import TableCell from '@mui/material/TableCell';
import {StyledTableRow } from './tableStyles/twoColourRows'
import { useEffect, useState } from 'react';
import { PnlTableCell } from './tableStyles/PnlTableCell';
import { doubleToString } from './Utils';

export default function ShareClassesPerformanceTable({fund, fundsClient,setCurrentShareClass}){
  
  const [shareClasses, setShareClasses] = useState(null);
    useEffect(async()=>{
      const shareClasses = await fundsClient.getShareClasses(fund.id);
      setShareClasses(shareClasses)
      setCurrentShareClass(shareClasses[0])
    },[fund])


    return(
        <DataTable columnNames={['Share Class', 'Net Asset Value', 'Return for Previous Day (%)'
        , 'Perf MTD (%)', 'Perf YTD (%)', 'Perf ITD (%)']}
         containerStyle={{ marginTop:'2vh', opacity:'0.9'}}>
           {shareClasses?.map(shareClass =>{
             return (<StyledTableRow onClick={(e) => setCurrentShareClass(shareClass)}>
             <TableCell align='center'>{shareClass.name}</TableCell>
             <TableCell align='center' style={{color:'blue'}}>{shareClass.latestNav}</TableCell>
             <PnlTableCell align='center'>{shareClass.performancePrevDay*100}</PnlTableCell>
             <PnlTableCell align='center'>{shareClass.performanceMtd*100}</PnlTableCell>
             <PnlTableCell align='center'>{shareClass.performanceYtd*100}</PnlTableCell>
             <PnlTableCell align='center'>{shareClass.performanceItd*100}</PnlTableCell>
              </StyledTableRow>
             )
           })}
    </DataTable>
    )
}