import React from 'react';
import FundCard from './FundCard'
import DefaultContainer from './DefaultContainer';
import Tracker from './Tracker';

export default function Funds({ funds, setFund }) {

  return(
    <DefaultContainer style={{backgroundColor:'white'}}>
      {funds?.map((item)=>{
        return ( 
          <div>
            <Tracker page={'Home'}/>
          <FundCard fund={item} setFund={setFund}/>
          <br/>
          </div>
        )
      })}
    </DefaultContainer>
  );
}