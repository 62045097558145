import {Button} from '@mui/material'
import {COLOURS} from '../variables'
export function addAlpha(color, opacity) {
    var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }
export const createDateAsUTC = (date) => {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
  }
export const distinct = (v,i,s) => s.indexOf(v) == i
export const doubleToString = (value,decimalPlaces) => (Math.round(value * 100) / 100).toFixed(decimalPlaces)
export const openAlertDialog = (title, contentText, openDialog, handleClose = null) =>{
    openDialog({
        title: title,
        contentText: contentText,
        cancelButton: false,
        submitButton: {
        component:<Button variant="contained" type='submit' onClick={(e) => {if(handleClose) {handleClose()}}}
            style={{backgroundColor:COLOURS.mainColor, color: COLOURS.white}}>Ok</Button>
        },
    });
}
export const TryActionWithCatchDialog = (action, openDialog) =>{
    try{
            action()
    }catch(error){
        openAlertDialog("Something Went Wrong",error.message, openDialog)
    }
}
export const TryActionWithCatchDialogAsync = async (promise, openDialog, errorMessage) =>{
    try{
            await promise()
    }catch(error){
        openAlertDialog("Something Went Wrong",errorMessage ? errorMessage(error) : (error.message ?? error), openDialog)
    }
}