import React, { useEffect, useRef } from 'react'
import ReactApexChart from 'react-apexcharts';
import { useState } from 'react';
import { COLOURS } from '../variables';
import {doubleToString} from './Utils'
import FromToDatePicker from './FromToDatePicker'
import LoadingIndicator from './LoadingIndicator'

export default function TimeSeriesChart (props) {
      const [data, setData]  = useState();
      const [from, setFrom] = useState();
      const [to, setTo] = useState();
      const options = {
        chart: {
          type: 'area',
          stacked: false,
          height: 350,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
          toolbar: {
            autoSelected: 'zoom',
            export: {
              csv: {
                filename: 'timeSeriesChart',
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toISOString().substring(0,10)
                }
              },
              svg: {
                filename: 'timeSeriesChart',
              },
              png: {
                filename: 'timeSeriesChart',
              }
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
        },
        title: {
          text: props.title,
          align: 'left'
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100]
          },
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return doubleToString(val,2)
            },
          },
          title: {
            text: props.xAxisTitle
          },
        },
        xaxis: {
          type: 'datetime',
        },
        tooltip: {
          shared: false,
          y: {
            formatter: function (val) {
              return doubleToString(val,2)
            }
          }
        }
      }
      useEffect(() => {
        let tempData = props.data
        if(from) 
        {
          tempData = tempData.filter(x=> x.x >= from)
        }
        if(to) 
        {
          tempData = tempData.filter(x=> x.x <= to)
        }
        setData(tempData)
      }, [props.data, from, to])
      
      const ref = useRef(null)
      if(!props.data) return <span>loading...</span>
    return (
      <div style={{color:COLOURS.fontColor }} ref={ref}>
        <LoadingIndicator trackerArea='timeseries-chart' innerRef={ref}/>
      <div style={{padding:'10px 0 10px 0'}}>
        <FromToDatePicker onFromChange={(dt)=> setFrom(dt)} onToChange={(dt)=> setTo(dt)}/>
      </div>
    <ReactApexChart options={options} series={[{ name: props.name, data: data }]} type="area" height={'500'}/>
    </div>)
}