import * as React from 'react';
import { DataGrid, GridToolbarContainer,GridToolbarExport, GridToolbarColumnsButton, GridToolbarFilterButton } from '@mui/x-data-grid';
import { COLOURS } from '../variables';
import {Box} from '@mui/material'

const MyToolbar = () =>{
  const style = {color:COLOURS.fontColor}
  return (
    <GridToolbarContainer>
      <GridToolbarExport  sx={style}/>
      <GridToolbarColumnsButton sx={style}/>
      <GridToolbarFilterButton sx={style}/>
    </GridToolbarContainer>
  );
}

    export default function MyDataGrid({rows=[],handleRowClick, editable=false
  , columns=[], toolbar, checkboxSelection=false,handleRowEdit, style={height:'500px', backgroundColor:'white'}}){
    return (
      <Box sx={{...style, '& .row':{'&:hover': {backgroundColor:COLOURS.tableHover}}}}>
        <DataGrid
          editMode='row'
          rows={rows}
          columns={columns.map(x=> {return {...x, flex:(1.0/columns.length), editable:editable, align:'center', headerAlign:'center'}})}
          pageSize={100}
          rowsPerPageOptions={[]}
          getRowClassName={(x) => 'row'}
          onRowClick={(x) => handleRowClick(x.row)}
          checkboxSelection={checkboxSelection}
          components={{ Toolbar: toolbar ?? MyToolbar }}
          experimentalFeatures={{ newEditingApi: true }}
          processRowUpdate={handleRowEdit}
        />
        </Box>
    )
}